<template>
    <div :class="className">
        <div class="o-select__inner">
            <span
                class="o-select__box"
                @click.stop="isFocused = !isFocused"
            >
                <span class="o-select__placeholder">
                    {{ option ? option[oKey] : required ? `${placeholder}*` : placeholder }}
                </span>
                <span class="o-select__arrow">
                    <icon icon="chevron" />
                </span>
            </span>
            <ul class="o-select__list">
                <li
                    v-for="(o, i) in options"
                    :key="`option-${i}`"
                    class="o-select__option"
                >
                    <button
                        type="button"
                        class="o-select__text"
                        :class="{ 'is-active' : activeIndex === i}"
                        @click="updateOption(o, i)"
                    >
                        {{ o[oKey] }}

                        <span
                            v-if="activeIndex === i"
                            class="o-select__cross"
                        />
                    </button>
                </li>
            </ul>
        </div>
        <app-input-text
            v-if="showInput"
            v-model="inputVal"
            :label="$t('form.specify')"
        />
        <input :name="name" type="text" :value="value" hidden>
    </div>
</template>

<script>

import Icon from 'objects/Icon';
import AppInputText from 'objects/AppInputText';

export default {
    name: 'AppSelect',
    components: {
        Icon,
        AppInputText,
    },
    props: {
        placeholder: {
            type: String,
            default: 'Placeholder',
        },
        name: {
            type: String
        },
        required: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => ({}),
        },
        as: {
            type: String,
            default: 'title:id'
        },
        opensUp: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        value: '',
        inputVal: false,
        option: false,
        isFocused: false,
        isFilled: false,
        activeIndex: -1,
        showInput: false,
    }),
    computed: {
        className() {
            let className = 'o-select'

            className += ` -open-${this.opensUp ? 'up' : 'down'}`

            if (this.disabled) {
                className += ' is-disabled'
            }
            if (this.isFilled) {
                className += ' is-filled'
            }
            if (this.isFocused) {
                className += ' is-focused'
            }

            return className
        },
        asKeyValue() {
            return this.as.split(':')
        },
        oKey() {
            return this.asKeyValue[0]
        },
        oValue() {
            return this.asKeyValue[1]
        }
    },
    created() {
        window.addEventListener('click', this.clickEvent = () => {
            this.isFocused = false
        });
    },
    methods: {
        updateOption(option, index=-1) {

            this.showInput = ['autre', 'other'].includes(option.title.toLowerCase())

            if (this.activeIndex === index) {
                this.option = false
                this.activeIndex = -1
            } else {
                this.option = option
                this.activeIndex = index
            }
        },
        setOption(value) {

            // Find option index
            const optionIndex = this.options.findIndex(option => option[this.oValue] === value)

            // Update option with value if exists
            if (optionIndex > -1) {
                this.updateOption(this.options[optionIndex], optionIndex)
            }
        },
        reset() {

            // If only one value is available, preselect it after load
            if ( this.options.length == 1 && this.required) {
                this.option = this.options[0]
                this.activeIndex = 0
            } else {
                this.option = false
                this.activeIndex = -1
            }
        }
    },
    watch: {
        option(option) {

            this.value = option === false ? '' : option[this.oValue]
            this.isFilled = this.value !== ''
            this.isFocused = false
            this.$emit('input', this.value)
        },
        inputVal(val) {
            let value = this.showInput ? this.value + `: ${val}` : this.value
            this.$emit('input', value)
        },
    },
    destroyed() {
        window.removeEventListener('click', this.clickEvent)
    }
};

</script>

<style lang="scss">

.o-select {
    --input-border: 1px solid var(--input-border-color);

    .o-input-text {
        margin-top: .5rem;
    }

    &.is-focused {
        --input-border: 1px solid var(--input-option-color-bg);
        z-index: 1;

        &.-open-up {
            .o-select__inner {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
        &.-open-down {
            .o-select__inner {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .o-select__arrow {

            &:before {
                opacity: .5;
                transform: scale(1);
            }

            .o-icon {
                animation: anim-select-icon .4s $in-out-quad forwards;
            }
        }

        .o-select__box {
            background: var(--input-option-color-bg);
            box-shadow: 0 0 1em rgba($color-dark, 0.15);
        }

        .o-select__list {
            z-index: 10;
            display: block;
            box-shadow: 0 .65em 1em rgba($color-dark, 0.10);
        }
    }
}

.o-select__inner {
    border: var(--input-border);
    border-radius: var(--input-border-radius);
}

.o-select__box {
    z-index: 0;
    display: flex;
    align-items: center;
    height: var(--input-height);
    padding-left: 1em;
    padding-right: var(--input-height);
    color: var(--input-color-text);
    background: var(--input-color-bg);
    border-radius: inherit;
    user-select: none;
    cursor: pointer;
    overflow: hidden;

    &:hover {

        .o-select__arrow:before {
            transform: scale(1);
        }
    }
}

.o-select__placeholder {
    display: block;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media #{md("xs", "max")} {
        max-width: 16em;
    }

}

.o-select__arrow {
    z-index: 0;
    position: absolute;
    top: calc((var(--input-height) - 1.25em)/2);
    right: calc((var(--input-height) - 1.25em)/2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    overflow: hidden;
}

.o-select__list {
    position: absolute;
    right: -1px;
    left: -1px;
    width: auto;
    max-height: 12em;
    padding: 0.25em 0;
    @include reset-list;
    border-right: var(--input-border);
    border-left: var(--input-border);
    background-color: var(--input-option-color-bg);
    overflow-y: auto;

    display: none;

    .-open-up & {
        bottom: 100%;
        border-top: var(--input-border);
        border-top-right-radius: var(--input-border-radius);
        border-top-left-radius: var(--input-border-radius);
    }

    .-open-down & {
        top: 100%;
        border-bottom: var(--input-border);
        border-bottom-right-radius: var(--input-border-radius);
        border-bottom-left-radius: var(--input-border-radius);
    }
}

.o-select__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .5em 1em;
    font-size: 0.9em;
    text-align: left;
    cursor: pointer;
    transition: background-color .2s ease-out;

    &:hover {
        background-color: $color-light;
    }

    &.is-active {
        font-weight: 600;
        color: var(--input-checked-color-text);
        background-color: var(--input-option-color-bg);

        &:hover {

            .o-select__cross {
                transform: rotate(90deg);
            }
        }
    }
}

.o-select__cross {
    display: block;
    width: .75em;
    height: .75em;
    transform: rotate(0deg);
    transition: transform .25s ease-out;

    &:after,
    &:before {
        @include pseudo-el($height: 2px, $bg: currentColor);
        position: absolute;
        top: 50%;
        left: 0;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
}


@keyframes anim-select-icon {
    0% {
        transform: translate(0);
    }

    50% {
        transform: translate(0, 100%);
    }

    50.001% {
        transform: translate(0, -100%) rotate(180deg);
    }

    100% {
        transform: translate(0) rotate(180deg);
    }
}

</style>
