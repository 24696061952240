<template>
    <div class="c-service">
        <boxed-content :data="true" :bgColor="element.color">
            <template v-if="element.images.length > 1">

                <slider
                    :slides="element.images"
                    ref="slider"
                    :fullheight="true"
                    :prevNext="true"
                    :show-index="true"
                    :zoom="true"
                    modifier="gallery"
                    class="c-service__slider"
                    :options="{
                        wrapAround: true,
                        draggable: false,
                    }"
                >
                    <template #beforeNav>
                        <icon
                            v-if="element.icon"
                            :icon="parseIcon(element.icon)"
                            bg-color="green-light"
                        />
                    </template>

                    <template #slide="image">
                        <asset :asset="image" :rounded="true" @loaded="refreshSlider()" />
                    </template>
                </slider>

                <div class="c-service__content">
                    <deco position="bottom" />
                    <grid-content>
                        <template #left>
                            <h2 class="c-service__title | t-t3">
                                {{ element.title }}
                            </h2>
                            <div
                                v-if="parseButtons(element.buttons).length"
                                class="c-service__buttons"
                            >
                                <span class="t-bold t-large">Ce que nous faisons</span>
                                <nav class="c-btn__group -vertical">
                                    <btn
                                        v-for="(button, i) in parseButtons(element.buttons)"
                                        :key="`${element.title}-button-${i}`"
                                        :label="button.label"
                                        :tag="button.tag"
                                        :download="button.download"
                                        :href="button.url"
                                        :iconBefore="button.icon"
                                        color="light"
                                    />
                                </nav>
                            </div>
                        </template>
                        <template #right>
                            <builder :blocks="element.layout" />
                        </template>
                    </grid-content>
                </div>
            </template>
            <template v-else>
                <clip-content
                    :icon="parseIcon(element.icon)"
                    :iconBgColor="element.iconBg || 'green-light'"
                    :image="element.images[0]"
                    :color="element.bgColor"
                >
                    <template #content>
                        <grid-content>
                            <template #left>
                                <h2 class="c-service__title | t-t3">
                                    {{ element.title }}
                                </h2>
                                <div
                                    v-if="parseButtons(element.buttons).length"
                                    class="c-service__buttons"
                                >
                                    <span class="t-bold t-large">Ce que nous faisons</span>
                                    <nav class="c-btn__group -vertical">
                                        <btn
                                            v-for="(button, i) in parseButtons(element.buttons)"
                                            :key="`${element.title}-button-${i}`"
                                            :label="button.label"
                                            :tag="button.tag"
                                            :download="button.download"
                                            :href="button.url"
                                            :iconBefore="button.icon"
                                            color="light"
                                        />
                                    </nav>
                                </div>
                            </template>
                            <template #right>
                                <builder :blocks="element.layout" />
                            </template>
                        </grid-content>
                    </template>
                </clip-content>
            </template>
        </boxed-content>
    </div>
</template>

<script>
import {parseButtons, parseIcon} from 'src/store/parse'

import BoxedContent from "layout/BoxedContent"
import GridContent from 'layout/GridContent'

import Btn from "components/Btn"
import ClipContent from "components/ClipContent"
import Slider from "components/Slider"

import Asset from "objects/Asset"
import Icon from 'objects/Icon'
import Deco from 'objects/Deco'

import Builder from 'builder/Builder'

export default {
    name: "ContentService",
    components: {
        Asset,
        BoxedContent,
        Btn,
        Builder,
        ClipContent,
        Deco,
        GridContent,
        Icon,
        Slider
    },
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        parseButtons,
        parseIcon
    }),
    methods: {
        refreshSlider() {
            this.$refs.slider.resize()
        }
    }
};
</script>

<style lang="scss">
.c-service {
    margin-bottom: 0;

    @media #{md("xs")} {
        margin-bottom: var(--grid-gutter);
    }

    @media #{md("sm")} {
        &:first-of-type {
            margin-top: calc(var(--header-height));
        }
    }
    @media #{md("md")} {
        &:first-of-type {
            margin-top: 0;
        }
    }

    // Redefine padding
    .l-boxed-content {
        --boxed-content-padding-x: 0;
        --boxed-content-padding-y: 0;


        // But keep it for children
        .l-boxed-content {
            --boxed-content-padding-x: var(--grid-gutter-half);
            --boxed-content-padding-y: var(--grid-gutter);

            @media #{md("xs")} {
                --boxed-content-padding-x: var(--grid-gutter);
            }
        }
        .c-clip-content {
            @media #{md("xs", "max")} {
                --margin: var(--grid-gutter-half);
            }
        }

        .l-boxed-content__inner {
            .c-clip-content__content,
            .c-service__content {
                padding: var(--grid-gutter) var(--grid-gutter-half);

                @media #{md("sm")} {
                    padding: var(--grid-gutter);
                }
            }
        }
    }

    // Redefine the grid
    .l-grid-content {
        --grid-col-gap: var(--grid-gutter);
        grid-template-columns: 1fr;

        @media #{md("md")} {
            --grid-col-gap: 0;
            grid-template-columns: 5fr 8fr;
        }

        &__col.-left {
            padding-right: var(--grid-gutter-half);
        }
    }

    // Redefine builder spacer
    .b {
        --block-spacer: var(--grid-gutter-half)
    }
}

.c-service__buttons {
    margin-top: var(--grid-gutter-half);

    .c-btn__group {
        margin-top: 1em;
    }
}

.c-service__slider {
    display: flex;
    flex-direction: column-reverse;

    .c-slider__slide {
        .o-asset {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .c-slider__side {
        justify-content: space-between;
        align-items: flex-end;
        margin: var(--grid-gutter-half) var(--grid-gutter-half) 0;

        @media #{md("sm")} {
            margin: var(--grid-gutter-half) var(--grid-gutter) 0;
        }
        > .o-icon {
            --svg-width: var(--grid-gutter);
        }
    }

    .c-slider__btn,
    .c-slide__count {
        --nav-bg: #{$color-light};
    }
}
</style>
