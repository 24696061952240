<template>
    <card modifier="business-area" ref="card">
        <template #asset>
            <asset
                v-if="element.image"
                :asset="element.image"
                :alt="element.title"
                ref="asset"
                class="business-area__asset"
            />
            <icon
                :icon="element.icon"
                bg-color="green-light"
                ref="icon"
                class="business-area__icon"
            />
        </template>
        <template #content>
            <h3 class="c-card__title | t-t4">{{ element.title }}</h3>
            <btn
                tag="router-link"
                :href="`/${element.uri}`"
                label="En savoir plus"
                color="green-emerald"
                ref="button"
                class="business-area__btn"
            />
        </template>

    </card>
</template>

<script>

import Card from 'components/Card'
import Btn from 'components/Btn'
import Asset from 'objects/Asset'
import Icon from 'objects/Icon'

import { gsap } from 'gsap/all'
export default {
    name: 'CardBusinessArea',
    components: {
        Card,
        Btn,
        Icon,
        Asset,
    },
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        animationRunning: false
    }),
    mounted() {
        this.timeline = gsap.timeline()

        this.card = this.$refs.card.$el
        this.button = this.$refs.button.$el
        this.icon = this.$refs.icon.$el
        this.svg = this.icon.querySelector('svg')
        this.asset = this.$refs.asset.$el
        this.parent = this.asset.parentNode

        if ( this.element.image && this.element.image.url ) {

            this.cloneAsset = this.asset.cloneNode(true)
            this.cloneImage = this.cloneAsset.querySelector('img')
            this.asset.classList.add('-original')
            this.cloneAsset.classList.add('-clone')
            gsap.set(this.cloneAsset, {
                transformOrigin: '50% 85%',
                position: 'absolute',
                width: '100%',
                height: '100%',
                bottom: '0',
                objectFit: 'cover',
                zIndex: '2'
            })

            this.parent.append(this.cloneAsset)
        }

        this.button.addEventListener('mouseenter', () => this.show())
        this.button.addEventListener('mouseleave', () => this.hide())
    },
    methods: {
        show() {

            this.animationRunning = true

            gsap.timeline({})
                .to(this.svg, {
                    scale: 0.8,
                    yPercent: 100,
                    duration: .3,
                    delay: 0,
                    ease: 'power2.in'
                })
                .to(this.icon, {
                    opacity: 0,
                    scale: 0.9,
                    yPercent: 50,
                    duration: .3,
                    delay: -.3,
                    ease: 'power2.in'
                })
                .to(this.cloneAsset, {
                    scale: 4, // todo: 3 on desktop, 4 on mobile
                    delay: -.1,
                    duration: .4,
                    ease: 'power2.in'
                })
                .to(this.cloneImage, {
                    filter: "blur(4px)",
                    '-webkit-filter': "blur(4px)",
                    delay: -.4,
                    duration: .8
                })
                .to(this.cloneAsset, {
                    borderTopLeftRadius: '100em',
                    borderTopRightRadius: '100em',
                    delay: -1.1,
                    duration: 1,
                    ease: 'power3.in',
                    onComplete: () => {
                        this.animationRunning = false
                    }
                })
        },
        hide() {

            if (this.animationRunning == true) {
                setTimeout(() => {
                    this.hide()
                }, 500)
                return
            }

            this.animationRunning = true

            gsap.timeline({})
                .to(this.cloneAsset, {
                    scale: 1,
                    delay: 0,
                    duration: .3,
                    ease: 'power2.out'
                })
                .to(this.cloneImage, {
                    filter: "blur(0px)",
                    '-webkit-filter': "blur(0px)",
                    delay: -.2,
                    duration: .3,
                    ease: 'power2.out'
                })
                .to(this.cloneAsset, {
                    borderTopLeftRadius: '0.625em',
                    borderTopRightRadius: '0.625em',
                    delay: -.65,
                    duration: .5,
                    ease: 'power2.out'
                })
                .to(this.icon, {
                    opacity: 1,
                    scale: 1,
                    yPercent: 0,
                    duration: .3,
                    delay: -.1,
                    ease: 'power2.out'
                })
                .to(this.svg, {
                    yPercent: 0,
                    scale: 1,
                    duration: .3,
                    delay: -.3,
                    ease: 'power2.out',
                    onComplete: () => {
                        this.animationRunning = false
                    }
                })
        }
    }
}
</script>

<style lang="scss">

.c-card.-business-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $color-green-dark;
    width: 100%;
    padding: 0;
    padding-bottom: 0;
    overflow: hidden;

    .c-card__asset {
        margin:  0 var(--grid-gutter) ;

        .o-asset {
            border-top-left-radius: $border-radius-sm;
            border-top-right-radius: $border-radius-sm;
            overflow: hidden;
            width: 100%;

            &.-original {
                opacity: 0;
            }
        }

        .o-icon {
            --svg-width: 3.5em;
            position: absolute;
            z-index: 3;
            top: calc(var(--svg-width) / 2 * -1);
            right: var(--grid-gutter-half);
            overflow: hidden;
        }
    }
    .c-card__content {
        // --inner-padding-x: 0;
        // --inner-padding-y: 0;
        padding-bottom: var(--grid-gutter);
        z-index: 2
    }

    .c-card__title {
        max-width: calc(var(--max-width) - var(--grid-gutter));
        color: $color-green-emerald;
    }
}

</style>
