<template>
    <div :class="className">
        <div
            class="c-accordion__header"
            @click="isOpen ? close() : open()"
        >
            <slot name="title">
                <icon
                    v-if="icon"
                    class="c-accordion__header--icon"
                    :icon="icon"
                    :bg-color="bgColor"
                />
                <h3
                    v-if="title"
                    class="c-accordion__title"
                >
                    {{ title }}
                </h3>
            </slot>
            <span class="c-accordion__icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </div>
        <div
            ref="container"
            class="c-accordion__container"
        >
            <div ref="inner" class="c-accordion__content">
                <slot>
                    <div
                        class="t-cms"
                        v-html="content"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

import { gsap } from 'gsap/all';
import Icon from 'objects/Icon'

export default {
    name: 'Accordion',
    components: {
        Icon
    },
    data: () => ({
        isOpen: false,
    }),
    props: {
        icon: {
            type: String,
            default: null
        },
        bgColor: {
            type: String,
            default: 'green-light'
        },
        title: {
            type: String,
            default: null,
        },
        triggerLabel: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        upsideDown: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        openDefault: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        className() {
            let classname = 'c-accordion'

            if(this.isOpen) {
                classname += ' is-open'
            }

            if(this.upsideDown) {
                classname += ` -upside-down`
            }

            return classname
        }
    },
    mounted() {

        if (this.openDefault)
            this.open()
    },
    methods: {
        open() {
            this.isOpen = true;

            const height = this.$refs.inner.offsetHeight;

            gsap.to(this.$refs.container, {
                duration: 1,
                height,
                opacity: 1,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 'auto'
                    });
                },
                ease: 'expo.out',
            });

            //const rect = this.$refs.container.getBoundingClientRect()
        },
        close() {
            this.isOpen = false;

            gsap.to(this.$refs.container, {
                duration: .6,
                height: 0,
                opacity: 0,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 0
                    });
                },
                ease: 'expo.out',
            });
        },
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        },
    },
};
</script>

<style lang="scss">

.c-accordion {
    --bg-color: transparent;
    --max-width: 46em;
    max-width: var(--max-width);
    background-color: var(--bg-color);
    border-radius: $border-radius-sm;

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
            background-color: var(--bg-color);
        }

        .c-accordion__icon {
            opacity: 1;
            // transform: rotate(-180deg);

            span {
                transition: transform .2s ease-in;

                &:nth-child(2) {
                    --size: 20%;
                    transform: translate(calc(-50%), -.4em) rotate(-45deg);
                }

                &:nth-child(3) {
                    --size: 20%;
                    transform: translate(calc(50% - .125em ), -.4em) rotate(45deg);
                }
            }
        }
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }
    }

    &__title {
        margin-right: var(--grid-gutter);
    }
}


.c-accordion__header {
    --header-bg: #{$color-light};
    display: flex;
    align-items: center;
    padding: 1em;
    border-radius: $border-radius-sm;
    user-select: none;
    position: relative;
    cursor: pointer;
    background: var(--header-bg);

    transition: box-shadow 0.3s $out-quad;

    &--icon {
        margin-right: 1em;
    }

    &:hover,
    .is-open &:hover {
        box-shadow: 0 0 .25rem .25rem rgba($color-dark, 0.1);
    }
}

.c-accordion__icon {
    --height: 3em;
    --width: 3em;
    display: block;
    width: var(--width);
    height: var(--height);
    position: absolute;
    border-radius: 50%;
    flex-shrink: 0;
    // opacity: .6;
    right: 0;
    top: 25%;
    transition: transform .6s $out-expo;

    span {
        --size: 25%;
        position: absolute;
        display: block;
        background-color: $color-green-dark;
        transition: transform .4s ease-out;
        height: 2px;
        width: var(--size);
        top: calc(50% - var(--size)/2);
        left: calc(50% - var(--size)/2);
        transform-origin: top;

        &:first-child {
            transform: translateY(calc(-50%)) rotate(90deg);
        }

        &:nth-child(2) {
            --size: 20%;
            transform: translate(calc(-50% + 2px)) rotate(45deg);
        }

        &:nth-child(3) {
            --size: 20%;
            transform: translate(calc(50% - 2px * 2)) rotate(-45deg);
        }
    }
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;
}


.c-accordion__content {
    // max-height: 400px;
    padding: 1em 0;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

</style>
