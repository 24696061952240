<template>
    <div :class="classNames">
        <div v-if="hero.title || hero.description" class="l-hero__text">
            <div class="t-content">
                <div class="l-hero__text--before" v-if="$slots.beforeContent">
                    <slot name="beforeContent" />
                </div>
                <anim-text
                    v-if="hero.title"
                    tag="h1"
                    ref="title"
                    :text="hero.title"
                    :reveal="false"
                    :lines="true"
                    :options="{ inDelay: 0.1 }"
                    :class="heading"
                />
                <anim-text
                    v-if="hero.description"
                    ref="description"
                    tag="p"
                    :text="hero.description"
                    :reveal="false"
                    :lines="true"
                    :options="{ inDelay: 0.2 }"
                />
                <div class="l-hero__text--after" v-if="$slots.afterContent">
                    <slot name="afterContent" />
                </div>
            </div>
        </div>

        <div class="l-hero__media" v-if="$slots.media">
            <slot name="media" />
        </div>
        <deco v-if="$slots.media" position="bottom" />
    </div>
</template>

<script>
import AnimText from 'objects/AnimText'
import Deco from 'objects/Deco'

export default {
    name: 'AppHero',
    components: {
        AnimText,
        Deco
    },
    props: {
        hero: {
            type: Object,
            default: () => { }
        },
        gap: {
            type: Boolean,
            default: true
        },
        heading: {
            type: String,
            default: "t-t2"
        },
        bgColor: {
            type: String
        }
    },
    data: () => ({
        revealClass: true,
        isRevealed: false,
    }),
    computed: {
        classNames() {

            let classes = 'l-hero | grid'

            if (this.gap)
                classes += ' -gap'

            if (this.bgColor)
                classes += ` -${this.bgColor}`

            if (this.$slots.media)
                classes += ' -has-media'

            if (this.revealClass) {
                classes += ' -reveal'

                if (this.isRevealed) {
                    classes += ' is-revealed'
                }
            }

            return classes
        }
    },
    methods: {
        reveal() {
            this.isRevealed = true
            if (this.$refs.title) this.$refs.title.show()
            if (this.$refs.description) this.$refs.description.show()
        }
    }
}
</script>

<style lang="scss">
.l-hero {
    --hero-bg: var(--header-bg);
    --hero-text-color: var(--header-text-color);
    padding: calc(var(--header-height) + var(--grid-gutter-half)) 0 0;
    color: var(--hero-text-color);
    z-index: 1;

    @media #{md("md")} {
        padding: 0;
    }

    &__text {
        padding: var(--grid-gutter) var(--grid-gutter-half);
        min-width: 300px;
        max-width: 520px;
        z-index: 2;

        @media #{md("md")} {
            padding: var(--grid-gutter);
            padding-top: calc(var(--header-height) + var(--grid-gutter));
        }

        .t-content {
            --text-margin-top: var(--grid-gutter-half);
            --btn-margin-top: 0;

            width: 100%;
            // max-width: 300px;

            @media #{md("md")} {
                // max-width: 375px;
            }
        }
    }

    &.-has-media {
        display: grid;

        &:before {
            content: "";
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            left: calc(var(--grid-start-x) * -1 + var(--grid-gutter-half));
            background: var(--hero-bg);
        }

        @media #{md("md")} {
            grid-template-columns: 1fr 2fr;
        }

        &__text {
            grid-row: 2/3;

            @media #{md("md")} {
                grid-row: 1/2;
            }
        }

        .l-hero__media {
            width: calc(100% - var(--grid-gutter-half));
            margin-left: var(--grid-gutter-half);
            grid-row: 1/2;
            z-index: 2;
            overflow: hidden;
            border-bottom-right-radius: $border-radius-sm;

            @media #{md("md")} {
                width: 100%;
                grid-column: 2/3;
                margin-left: 0;
            }

            .o-asset {
                width: 100%;
                overflow: hidden;
            }
            .o-asset__img {
                object-fit: cover;
                height: 100%;
            }
        }
    }

    &.-gap {
        margin-bottom: calc(-1 * var(--grid-gutter));

        &:before {
            @media #{md("md")} {
                height: calc(100% - var(--grid-gutter));
            }
        }

        .l-hero__text {
            @media #{md("md")} {
                padding-bottom: calc(var(--grid-gutter) * 2);
            }
        }

        .l-hero__media {
            border-top-left-radius: $border-radius-sm;
            border-bottom-left-radius: $border-radius-sm;
            border-bottom-right-radius: $border-radius-sm;

            @media #{md("md")} {
                border-top-left-radius: 0;
            }

            @media #{md("xl", "max")} {
                border-bottom-right-radius: 0;
            }

            .o-asset {
                height: 100%;
            }
        }

        & + * {
            padding-top: calc(var(--section-padding) + var(--grid-gutter));
        }

        .o-deco {
            @media #{md("md")} {
                bottom: calc( var(--grid-gutter) + 1px);
            }
        }
    }

    // Reveal animations
    &.-reveal {
        .l-hero__text--before,
        .l-hero__text--after {
            overflow: hidden;

            & > * {
                opacity: 0;
                transform: translateY(2rem);
                transition: transform 0.5s ease, opacity 0.5s ease;
                will-change: transform;
            }
        }

        .l-hero__text--before {
            & > * {
                transition-delay: 0s;
            }
        }
        .l-hero__text--after {
            & > * {
                transition-delay: 0.3s;
            }
        }

        .l-hero__media {
            overflow: hidden;

            .o-asset {
                background-color: var(--hero-bg);
            }
            .o-asset__img {
                //opacity: 0;
                //transition: opacity 0.75s $out-expo;
                //transition-delay: 0.4s;
                //will-change: opacity;
            }
        }

        &.is-revealed {
            .l-hero__text--before,
            .l-hero__text--after {
                & > * {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            .l-hero__media {
                .o-asset__img {
                   //opacity: 1;
                }
            }
        }
    }
}
</style>
