<template>
    <section v-if="blocks.length > 0" class="b">
        <div
            v-for="(block, i) in blocks"
            :key="`block-${i}`"
            class="b-item"
            :class="block.typeHandle | snake"
        >
            <component :is="block.typeHandle" :data="block" />
        </div>
    </section>
</template>

<script>

import BlockAccordions from 'builder/BlockAccordions'
import BlockBoxedContent from 'builder/BlockBoxedContent'
import BlockContent from 'builder/BlockContent'
import BlockContentImage from 'builder/BlockContentImage'
import BlockGallery from 'builder/BlockGallery'
import BlockHeading from 'builder/BlockHeading'
import BlockImage from 'builder/BlockImage'
import BlockSpacer from 'builder/BlockSpacer'
import BlockTestimonies from 'builder/BlockTestimonies'

export default {
    name: 'Builder',
    props: {
        blocks: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        BlockAccordions,
        BlockBoxedContent,
        BlockContent,
        BlockContentImage,
        BlockGallery,
        BlockHeading,
        BlockImage,
        BlockSpacer,
        BlockTestimonies
    }
};

</script>

<style lang="scss">
.b {
    --block-spacer: var(--grid-gutter);

    .b-item {
        &:nth-child(n + 2) {
            margin-top: var(--block-spacer);
        }

        & + .b-item.block-accordions {
            margin-top: 1.5rem;
        }

        &:nth-last-child(n + 2) {
            @media #{md("xs", "max")} {
                margin-bottom: var(--block-spacer);
            }
        }

        & + .block-boxed-content {
            @media #{md("xs", "max")} {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}
</style>
