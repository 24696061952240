<template>
    <card modifier="article">
        <template #asset v-if="element.image">
            <asset :asset="element.image" />
            <btn
                :label="element.category.label || 'Communiqué'"
                :color="element.category.color || 'green-pastel'"
                tag="span"
                class="c-card__tag"
            />
        </template>

        <template #content>
            <btn
                v-if="!element.image"
                :label="element.category.label"
                :color="element.category.color || 'green-pastel'"
                tag="span"
                class="c-card__tag"
            />
            <h4 class="c-card__title | t-t4">{{ element.title }}</h4>
            <p>{{ element.excerpt }}</p>
            <btn
                tag="router-link"
                :href="link"
                color="light"
                label="En savoir plus"
            />
        </template>
    </card>
</template>

<script>
import Card from 'components/Card'
import Asset from '../objects/Asset.vue'
import Btn from './Btn.vue'
export default {
    name: 'CardArticle',
    components: {
        Card,
        Asset,
        Btn,
    },
    props: {
        element: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        link() {
            return `/${this.element.uri}`
        }
    }
};

</script>

<style lang="scss">
.c-card.-article {
    --max-width: 100%;
    border-radius: $border-radius-sm;
    overflow: hidden;

    .c-card__asset {
        .c-card__tag {
            left: var(--grid-gutter-half);
        }
    }
    .c-card__content {
        --inner-padding-x: var(--grid-gutter-half);
        padding-bottom: var(--grid-gutter-half);
        background: $color-green-light;

        .c-card__tag {
            top: calc(-1 * var(--grid-gutter));
        }

        .c-card__title {
            margin: 0;
        }
    }

    .c-card__tag {
        position: absolute;
        top: 0;
        left: 0;

        .c-btn__inner {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
</style>
