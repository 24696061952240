<template>
    <footer class="l-footer">
        <app-section class="footer">
            <div class="l-footer__form">
                <div class="l-footer__left">
                    <h2 class="t-t2">Faites appel <br> à nos services</h2>
                    <p class="t-regular">Pour toute question, ou demande <br> d'information, contactez-nous !</p>
                </div>

                <div class="l-footer__right">
                    <app-form
                        formTemplate="contact"
                        :inputs="inputs"
                    />

                    <deco position="bottom" />
                </div>
            </div>

            <div class="l-footer__particulars">
                <div class="l-footer__left">
                    <div class="l-footer__logo">
                        <app-logo className="l-footer-logo__lottie" />
                    </div>
                    <div class="l-footer__address">
                        <h4 class="t-t5">Adresse</h4>
                        <p class="t-regular">
                            45 rue Dalhousie,<br>
                            Québec (QC) <br>
                            G1K8S3
                        </p>
                    </div>
                </div>
                <div class="l-footer__right">
                    <div class="l-footer__contact">
                       <h4 class="t-t5">Contact</h4>
                       <contact-info />
                    </div>
                    <div class="l-footer__socials">
                        <h4 class="t-t5">Nous suivre</h4>
                        <social-medias />
                    </div>
                    <div class="l-footer__copyright">
                        <copyright />
                    </div>

                    <deco position="bottom" />

                </div>
            </div>
        </app-section>
    </footer>
</template>

<script>
import AppSection from 'layout/AppSection'


import Icon from 'objects/Icon'
import Deco from 'objects/Deco'
import ContactInfo from 'objects/ContactInfo'
import Copyright from 'objects/Copyright'

import AppForm from 'components/AppForm'
import Btn from 'components/Btn'
import SocialMedias from 'components/SocialMedias'
import AppLogo from 'objects/AppLogo'

export default {
    name: 'AppFooter',
    components: {
        AppSection,
        AppForm,
        Btn,
        ContactInfo,
        Copyright,
        Deco,
        Icon,
        SocialMedias,
        AppLogo
    },
    data() {
        return {
            inputs: [
                {
                    label: 'Nom complet',
                    name: 'fullName',
                    required: true,
                    half: true,
                },
                {
                    label: 'Entreprise',
                    name: 'enterprise',
                    required: true,
                    half: true,
                },
                {
                    label: 'Courriel',
                    name: 'fromEmail',
                    required: true,
                    half: true,
                },
                {
                    label: 'Service souhaité',
                    name: 'service',
                    options: [
                        {
                            title: 'Service 1'
                        },
                        {
                            title: 'Service 2'
                        },
                        {
                            title: 'Service 3'
                        },
                        {
                            title: 'Service 4'
                        }
                    ],
                    as: 'title:title',
                    half: true,
                },
                {
                    label: 'Message',
                    type: 'textarea',
                    name: 'body'
                },
            ]
        }
    }
};

</script>

<style lang="scss">

.l-footer {
    overflow-x: hidden;

    .l-section {
        padding-bottom: 0;
    }

    .t-t5 {
        padding-bottom: 1em;
    }
}

.l-footer__form,
.l-footer__particulars {
    display: grid;
    grid-template-columns: 1fr;

    @media #{md("sm")} {
        grid-template-columns: 4fr 8fr;
    }
    @media #{md("md")} {
        grid-template-columns: 1fr 2fr;
    }

    .l-footer__right {
        &:after {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100vw;
            background: $color-green-light;
            height: 100%;
            z-index: 0;
        }
    }
}

.l-footer__form {

    @media #{md("sm")} {
        grid-template-columns: 4fr 8fr;
    }

    .l-footer__left {
        margin-bottom: var(--grid-gutter);

        @media #{md("sm")} {
            margin-top: var(--grid-gutter);
            margin-bottom: 0;
            padding-right: var(--grid-gutter);
        }

        @media #{md("md")} {
            border-bottom: 1px solid $color-dark;
        }

        p {
            margin-top: var(--grid-gutter-half);
        }
    }

    .l-footer__right {

        &:after {
            border-top-left-radius: $border-radius-sm;

            @media #{md("sm", "max")} {
                border-bottom-left-radius: $border-radius-sm;
            }
        }

        .c-form {
            z-index: 1;

            @media #{md("md")} {
                border-bottom: 1px solid $color-dark;
            }
        }
    }

    .o-deco {
        right: calc( var(--grid-gutter-half) * -1);

        @media #{md("sm")} {
            display: none;
        }
    }
}


.l-footer__particulars {

    .c-btn {
        --btn-padding-y: 0.5em;
        --btn-padding-x: 0.75em;
    }

    .o-deco {
        right: calc(var(--grid-start-x) * -1 );
    }

    .l-footer__left,
    .l-footer__right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--grid-gutter);

        & > * {
            z-index: 1;
        }

        @media #{md("sm", "max")} {
            border-top: 1px solid currentColor;
            margin-top: var(--grid-gutter);
        }
    }

    .l-footer__left {
        align-items: flex-start;
        padding: var(--grid-gutter) 0;
    }

    .l-footer__right {
        padding: 0 0 var(--grid-gutter);

        @media #{md("sm")} {
            grid-template-columns: repeat(3, auto);
            padding: var(--grid-gutter) 0;
            padding-left: var(--grid-gutter);
        }

        &:after {
            @media #{md("sm", "max")} {
                background: none;
            }
        }

        .l-footer__socials {
            @media #{md("md", "max")} {
                grid-column: 1/2;
                grid-row: 1/2;
            }
        }
    }

    .l-footer__logo {
        img, svg {
            max-width: 7em;
        }
    }

    .l-footer__copyright {
        align-self: end;
        padding-bottom: 1em;

        @media #{md("md", "max")} {
            grid-column: 1/3;
        }

        @media #{md("md")} {
            text-align: right;
        }
    }
}
</style>
