<template>
    <div>
        <app-hero
            v-if="page.date"
            :hero="hero"
            ref="hero"
            :gap="page.image !== null"
            class="p-articles__header"
        >
            <template #beforeContent>
                <tag v-if="page.category" :label="page.category.label" />
            </template>
            <template #afterContent>
                <btn
                    label="Retour au blogue"
                    modifier="underline"
                    tag="router-link"
                    :href="SECTION_HANDLES.BLOG | resolver"
                />
            </template>
            <template #media>
                <asset v-if="page.image" :asset="page.image" />
                <div v-else></div>
            </template>
        </app-hero>

        <app-section class="p-articles__content">
            <grid-content :sticky="true">
                <template #left>
                    <div class="p-articles__socials">
                        <h3 class="t-bold t-large">Partager</h3>
                        <btn
                            v-for="(social, i) in socialLinks"
                            modifier="border"
                            :key="`p-article-social-link-${i}`"
                            :label="social.label"
                            :icon-before="social.icon"
                            tag="a"
                            :href="social.shareUrl + currentUrl"
                        />
                    </div>
                </template>
                <template #right>
                    <div class="t-cms" v-html="page.body"></div>
                    <div class="p-articles__pagination">
                        <span>
                            <btn
                                v-if="prev"
                                tag="router-link"
                                :href="`/${prev.uri}`"
                                label="Article précédent"
                                modifier="underline"
                                class="btn__prev"
                            />
                        </span>
                        <btn
                            label="Retour au blogue"
                            tag="router-link"
                            :href="SECTION_HANDLES.BLOG | resolver"
                            modifier="underline"
                            class="btn__back"
                        />
                        <span>
                            <btn
                                v-if="next"
                                tag="router-link"
                                :href="`/${next.uri}`"
                                label="Article suivant"
                                modifier="underline"
                                class="btn__next"
                            />
                        </span>
                    </div>
                </template>
            </grid-content>
        </app-section>
    </div>
</template>

<script>

import AppHero from 'layout/AppHero'
import AppSection from 'layout/AppSection'
import GridContent from 'layout/GridContent'

import Asset from 'objects/Asset'
import Tag from 'objects/Tag'
import Btn from 'components/Btn'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Articles',
    components: {
        AppHero,
        AppSection,
        Asset,
        Tag,
        Btn,
        GridContent,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        prev: null,
        next: null,
        socialLinks: [
            {
                label: 'Facebook',
                icon: 'facebook',
                shareUrl: 'https://facebook.com/sharer/sharer.php?u='
            },
            {
                label: 'LinkedIn',
                icon: 'linkedin',
                shareUrl: 'https://linkedin.com/sharing/share-offsite/?url='
            }
        ]
    }),
    computed: {
        hero() {
            return {
                title: this.page.title,
                description: `<span class="t-regular">Publié le ${this.page.date}</span>`
            }
        },
        currentUrl() {
            return window.location.href
        }
    },
    methods: {
        getSiblings() {

            const id = this.page.id,
                  date = this.page.datePost

            this.$store.dispatch('articles/getSiblings', {id, date})
            .then(result => {
                this.prev = result.prev
                this.next = result.next
            })
        },
    },
    watch: {
        page(obj) {
            if (obj && obj.datePost) {
                this.getSiblings()
            }
        }
    }
};

</script>

<style lang="scss">
.p-articles {
    --header-bg: #{$color-green-light};

    .l-hero {
        .c-btn.-underline {
            --btn-hover-bg-color: #{$color-light};
        }

        //
        h1 {
            word-break: break-all;
        }
    }

    &__socials {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1em;
        place-items: start;

        //Fix icon border bottom on edge
        padding-bottom: 1px;
    }

    &__content {
        .t-cms {
            p {
                max-width: 520px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        @media #{md("xs", "max")} {

            .btn__back {
                display: none;
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: space-between;
        margin: var(--grid-gutter) 0;
    }

    .l-grid-content {

        @media #{md("sm")} {
            --grid-col-gap: 0;
        }
        .-right {
            @media #{md("md")} {
                padding: 0 var(--grid-gutter);
            }
        }
    }
}
</style>
