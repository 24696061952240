<template>
    <header :class="className" class="grid" @mouseenter="isHidden = false">
        <div class="l-header__inner">
            <div class="l-header__top">
                <app-logo
                    ref="logo"
                    className="l-header__logo"
                />
                <div class="o-btn-toggle" @click="navIsOpen = !navIsOpen"></div>
            </div>

            <div class="l-nav">
                <div class="l-nav__container">
                    <nav
                        itemscope
                        itemtype="http://schema.org/SiteNavigationElement"
                    >
                        <ul class="l-nav__list">
                            <li
                                v-for="(item, i) in nav"
                                :key="i"
                                class="l-nav__item"
                                itemprop="name"
                                :style="`--idx: ${i + 1}`"
                            >
                                <router-link
                                    :to="item.path"
                                    :title="item.entry.title"
                                    class="l-nav__link | t-t4"
                                    :class="{ 'is-active': item.path == activeRoute }"
                                    exact
                                    itemprop="url"
                                >
                                    <anim-text
                                        :text="
                                            item.label ? item.label : item.entry.title
                                        "
                                        :visible="navIsOpen"
                                        :lines="true"
                                        :options="{
                                            inDelay: 0.5 + i / 10,
                                            outDuration: 0.3,
                                            outDelay: 0,
                                        }"
                                    />
                                </router-link>
                            </li>
                        </ul>
                    </nav>

                    <div class="l-nav__footer">

                        <div class="l-nav__socials">
                            <h4 class="-title | t-t5">Nous suivre</h4>
                            <social-medias />
                        </div>

                        <div class="l-nav__contact">
                           <h4 class="-title | t-t5">Contact</h4>
                           <contact-info />
                        </div>

                        <div class="l-nav__copyright">
                            <copyright />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import AnimText from 'objects/AnimText'
import Icon from 'objects/Icon'
import ContactInfo from 'objects/ContactInfo'
import Copyright from 'objects/Copyright'

import Btn from 'components/Btn'
import SocialMedias from 'components/SocialMedias'
import AppLogo from 'objects/AppLogo'

import { mapState } from 'vuex'

export default {
    name: 'AppHeader',
    components: {
        AnimText,
        Btn,
        ContactInfo,
        Copyright,
        Icon,
        SocialMedias,
        AppLogo
    },
    data: () => ({
        navIsOpen: false,
        activeRoute: '/'
    }),
    computed: {
        ...mapState({
            nav: state => state.global.navs
        }),
        className() {
            let classname = 'l-header'

            return classname
        },
        showActiveLink() {
            return this.navIsOpen
        }
    },
    methods: {

        openNav() {
            this.navIsOpen = true

        },
        closeNav() {
            this.navIsOpen = false

        },
        toggleNav() {
            if (this.navIsOpen) {
                this.closeNav()
            } else {
                this.openNav()
            }
        }
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggleNav', isOpen)
        },
        $route(_newRoute) {
            this.activeRoute = _newRoute.path
            this.closeNav()
        }
    }
};

</script>

<style lang="scss">
.l-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;

    height: var(--header-height);
    padding: 0;
    color: var(--header-text-color);

    @media #{md("md")} {
        position: absolute;
        z-index: initial;
        display: grid;
        gap: var(--grid-gutter);
        grid-template-columns: 1fr 2fr;

        padding-left: var(--grid-gutter);
        padding-right: var(--grid-gutter);
    }

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: var(--header-bg);

        @media #{md("md", "max")} {
            transition: height 0.3s ease 0.3s;
            will-change: height;
        }
    }

    &__inner {
        @media #{md("md")} {
            padding-right: var(--grid-gutter);
            z-index: 10;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: var(--header-bg);
        z-index: 10;

        padding: var(--grid-gutter-half) var(--grid-gutter-half);

        @media #{md("md")} {
            padding-left: 0;
            padding-right: calc(var(--grid-gutter-half) / 2);
        }
    }

    &__logo {
        .o-icon {
            svg path {
                fill: var(--header-text-color);
            }
        }
    }

    .o-btn-toggle {
        min-width: 2em;
        height: 2em;
        cursor: pointer;

        &:before,
        &:after {
            content: "";
            position: absolute;
            right: 0;
            height: 0.125em;
            background: var(--header-text-color);
            transition: width 0.3s ease, transform 0.3s ease;
        }

        &:before {
            width: 65%;
            top: 20%;
        }
        &:after {
            width: 100%;
            top: 50%;
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }
    }

    .nav-is-open & {
        &:before {
            @media #{md("md", "max")} {
                height: 100vh;
                transition: height 0.5s ease 0s;
            }
        }
    }

    .header-lottie__logo {
        position: absolute;
        top: 0.1rem;
        left: 0rem;
        width: 1.5em;
        svg path {
            fill: currentColor;
        }
    }
}

.nav-is-open {

    .o-btn-toggle {

        &:before {
            transform: rotate(45deg) translate(0.45em, 0.45em);
            width: 100%;
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.l-nav {
    --nav-transition-duration: 0.6s;
    padding: var(--grid-gutter) var(--grid-gutter-half);
    padding-bottom: 0;

    //overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 5;
    background: var(--header-bg);
    width: 100%;
    transform: translateY(calc(-100% + 2px));
    transition: transform var(--nav-transition-duration) ease 0.2s;

    @media #{md("md")} {
        padding-left: 0;
        padding-right: var(--grid-gutter);
    }

    .nav-is-open & {
        transform: translateY(0);
        transition-delay: 0s;

        @media #{md("md", "max")} {
            max-height: calc(100vh - var(--header-height));
            overflow-y: auto;
        }

        .l-nav__link.is-active {
            border-color: currentColor;
        }
    }
}

.l-nav__container {
    padding-bottom: var(--grid-gutter);

    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 1px;
        background: currentColor;

        transform: scaleX(1);
        transform-origin: center center;
        transition: transform 0.2s ease 0.2s;
        will-change: transform;
    }
}

.l-nav__list {
    list-style-type: none;
    display: grid;
    grid-gap: 2em;

    .l-nav__link {
        display: inline-block;
        transform-origin: left;
        transform: scale(0.9);
        transition: 0.3s transform $in-out-quad, border-color 0.4s 1.4s;

        &:hover, &.is-active {
            transform: scale(1.1);
        }

        &.is-active {
            border-bottom: 1px solid transparent;
            padding-bottom: 0.5rem;
        }
    }
}

.l-nav__footer {
    margin-top: calc( var(--grid-gutter) * 2);

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gutter);

    .l-nav__copyright {
        grid-column: 1/3;
    }

    .-title {
        margin-bottom: .75em;
    }

    //Transitions
    opacity: 0;
    transition: opacity 0.2s ease 0.2s;

    .nav-is-open & {
        opacity: 1;
        transition: opacity 0.5s ease 1.25s;
    }

    @media #{md("md")} {
        display: none;
    }

    .c-btn {
        --btn-hover-bg-color: #{$color-green-emerald};
        --btn-hover-text-color: #{$color-dark};
    }
}
</style>
