import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'

// Use router
Vue.use(VueRouter)

// Views
import * as Components from './templates/views'

// Dynamic Routes from Craft
const dynamicRoutes = window.__initialData__.routes.map(r => ({ ...r, component: Components[r.component] }));

// Static routes
const staticRoutes = [
    {
        name: 'Error404',
        path: '*',
        redirect: '/404'
    }
]

// Merge dynamic and static routes
const routes = [...dynamicRoutes, ...staticRoutes]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    linkActiveClass: 'is-active',
})

// Before each route load
router.beforeEach((to, from, next) => {

    // Avoid loader on Blog filters
    const sameRoute = from.name === to.name && (to.name === 'blog' )

    if (!sameRoute) {

        store
            .dispatch('global/loadContent', to)
            .then(next)

    } else {
        next()
    }
})

// Link resolver
const linkResolver = section => {

    // Match component name/route
    section = section.replace('Single', '')

    const route = routes.find(route => route.meta.section === section)

    if(route) {
        return route.path
    } else {
        return '/'
    }
}

// Export
export default router
export { router, routes, linkResolver }
