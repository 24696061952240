<template>
    <div v-if="data && data.accordions.length" class="b-accordions">
        <accordion
            v-for="(accordion, i) in data.accordions"
            :key="`b-accordions-${i}`"
            :title="accordion.heading"
            :icon="parseIcon(accordion.icon)"
            :bg-color="accordion.bgColor"
            :openDefault="i === 0 && data.openFirst ? true : false"
        >
            <div class="t-cms" v-html="accordion.content" />

            <div
                v-if="accordion.buttons && parseButtons(accordion.buttons)"
                class="c-accordion__buttons"
            >
                <span class="t-bold t-large">{{ accordion.subtitle }}</span>

                <div class="c-btn__group -vertical">
                    <btn
                        v-for="(button,i) in parseButtons(accordion.buttons)"
                        :key="`accordeon-${i}`"
                        :label="button.label"
                        :tag="button.tag"
                        :href="button.url"
                        :download="button.download"
                        :iconBefore="button.icon"
                        color="light"
                    />
                </div>

            </div>
        </accordion>
    </div>
</template>

<script>
import {parseIcon, parseButtons} from 'src/store/parse'

import Accordion from 'components/Accordion'
import Btn from 'components/Btn'

export default {
    name: 'BlockAccordions',
    data: () => ({
        parseIcon,
        parseButtons
    }),
    components: {
        Accordion,
        Btn
    },
    props: {
        data: false
    }
};

</script>

<style lang="scss">
.b-accordions {
    .c-accordion + * {
        margin-top: var(--grid-gutter-half);
    }

    .c-accordion__buttons {
        margin-top: var(--grid-gutter-half);

        .c-btn__group {
            margin-top: 1em;
        }
    }
}
</style>
