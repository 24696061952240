<template>
    <div
        v-if="data"
        class="b-content"
        :class="{
            '-padded': data.padded,
            '-columns': data.columns
        }"
    >
        <div class="b-content__inner | t-cms" v-html="data.content"></div>
    </div>
</template>

<script>
export default {
    name: "BlockContent",
    props: {
        data: false
    }
}
</script>

<style lang="scss">
.b-content {
    &.-padded {
        max-width: 22em;

        @media #{md("sm")} {
            padding-left: var(--grid-gutter);
        }
        @media #{md("md")} {
            max-width: 26em;
        }
    }

    &.-columns {
        .b-content__inner {
            @media #{md("md")} {
                columns: 2;
                column-gap: var(--grid-gutter);
            }
        }
    }
}
</style>
