<template>
    <div :class="classNames">
        <asset :asset="image" :cover="true" class="c-clip-content__asset" />
        <div
            class="c-clip-content__inner"
            :class="{ '-not-collapsed': !collapse }"
        >
            <div class="c-clip-content__cut" :style="styles">
                <asset
                    v-if="icon && typeof icon == 'object'"
                    :asset="icon"
                    :cover="true"
                    :class="`c-clip-content__icon -asset -${position}`"
                />
                <icon
                    v-else-if="icon"
                    :icon="icon"
                    :bg-color="iconBgColor"
                    :class="`c-clip-content__icon -icon -${position}`"
                />
            </div>

            <slot name="default" />

            <div v-if="$slots.content" class="c-clip-content__content">
                <deco />
                <slot name="content"> </slot>
            </div>

        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import Icon from 'objects/Icon'
import Deco from 'objects/Deco'

export default {
    name: 'ClipContent',
    components: {
        Asset,
        Icon,
        Deco
    },
    props: {
        image: {
            type: Object,
            default: null
        },
        icon: {
            type: String | Array,
            default: null
        },
        color: {
            type: String,
            default: null,
            validator: (color) => {
                return ['dark', 'light', 'emerald', 'green-light', 'green-pastel', 'orange'].indexOf(color) !== -1
            }
        },
        iconBgColor: {
            type: String,
            default: null
        },
        collapse: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'left',
            validator: (pos) => {
                return ['left', 'right'].indexOf(pos) !== -1
            }
        }
    },
    computed: {
        styles() {

            let styles = (this.image) ? `background-image: url(${this.image.url});` : `background-color: var(--bg-color);`

            return styles
        },
        classNames() {
            let classnames = 'c-clip-content'

            if (this.image) classnames += ' -has-image'
            if (this.color) classnames += ` -${this.color}`

            return classnames
        }
    }
};

</script>

<style lang="scss">
.c-clip-content {
    --radius: calc( var(--grid-gutter) / 1.2 );
    --diameter: calc(var(--radius) * 2);
    --margin: var(--grid-gutter);
    --ratio: 0.6;

    position: relative;
    margin-top: var(--radius);

    &.-has-image {
        margin-top: 0;
    }

    .c-clip-content__icon {
        position: absolute;

        bottom: var(--radius);
        transform: translate(-50%, 50%);

        &.-icon {
            --svg-width: calc(var(--diameter) * var(--ratio));
        }

        &.-asset {
            width: calc(var(--diameter) * var(--ratio));
            height: calc(var(--diameter) * var(--ratio));
            border-radius: 100%;
            overflow: hidden;
        }

        &.-left {
            left: calc(var(--margin) + var(--radius));
        }
        &.-right {
            right: calc(var(--margin) + var(--radius));
        }
    }
}

.c-clip-content__asset {
    height: 28rem;

    .o-asset__img {
        object-position: bottom;
    }
}

.c-clip-content__cut {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--radius);
    z-index: 1;

    clip-path: circle(
        var(--radius) at calc(var(--radius) + var(--margin)) 0
    );
    background-size: cover;
    background-position: bottom;
}

.c-clip-content__content {
    .o-deco {
        z-index: 0;
        &.-top {
            top: calc(var(--radius) * -1);
        }
        &.-bottom {
            bottom: calc(var(--radius) * -1);
        }
    }
}

.c-clip-content__inner {
    min-height: calc(var(--radius) * 2);
    margin-top: calc(var(--radius) * -1);

    &.-not-collapsed {
        padding-top: var(--radius);
        background: var(--bg-color); // to remove
    }
    .-light & {
        --bg-color: #{$color-light};
    }

    .-green-light & {
        --bg-color: #{$color-green-light};
    }

    .-green-pastel & {
        --bg-color: #{$color-green-pastel};
    }
    .-green-emerald & {
        --bg-color: #{$color-green-emerald};
    }
    .-orange & {
        --bg-color: #{$color-orange};
    }
}
</style>
