<template>
    <div
        v-if="data.testimonies.length"
        class="b-testimonials"
    >
        <card-grid
            :cards="data.testimonies"
            :slider="false"
            card-type="testimonial"
        />
    </div>
</template>

<script>

import CardGrid from 'components/CardGrid'

export default {
    name: 'BlockTestimonies',
    components: {
        CardGrid
    },
    props: {
        data: false
    },
    data: () => ({
    })
};

</script>

<style lang="scss">

.b-testimonials {
    margin-top: calc(var(--grid-gutter) + var(--grid-gutter-half));

    .c-card-grid {
        --grid-custom-gap: var(--grid-gutter);

        .c-card-grid__grid {
            row-gap: calc(var(--grid-gutter) + var(--grid-gutter-half));
        }
    }
}

</style>
