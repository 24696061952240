<template>
    <div
        class="l-boxed-content"
        :class="`-${this.bgColor}`">
        <div
            class="l-boxed-content__inner"
        >
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: 'BoxedContent',
    props: {
        bgColor:{
            type: String,
            default: 'green-light',
            validator: (color) => {
                return ['light', 'green-light', 'green-pastel', 'green-emerald', 'orange'].indexOf(color) !== -1
            }
        }
    }
};

</script>

<style lang="scss">
    .l-boxed-content {
        --boxed-content-padding-x: var(--grid-gutter-half);
        --boxed-content-padding-y: var(--grid-gutter);

        @media #{md("xs")} {
            --boxed-content-padding-x: var(--grid-gutter);
        }

        padding: var(--boxed-content-padding-y) var(--boxed-content-padding-x);
        overflow: hidden;

        @media #{md("xs", "max")} {
            margin-right: calc(-1 * var(--grid-gutter-half));
            margin-left: calc(-1 * var(--grid-gutter-half));
        }

        @media #{md("xs")} {
            border-radius: $border-radius-sm;
        }

        // Colors
        &.-light {
            background: #{$color-light};
        }

        &.-green-light {
            background: #{$color-green-light};
        }

        &.-green-pastel {
            background: #{$color-green-pastel};
        }
        &.-green-emerald {
            background: #{$color-green-emerald};
        }
        &.-orange {
            background: #{$color-orange};
        }
    }
</style>
