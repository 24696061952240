<template>
    <div
        v-if="inputs.length > 0"
        class="c-form"
        :class="{ 'is-sending': pending }"
    >
        <slot name="before" />

        <div v-if="error" class="c-form__error | t-cms">
            <p>
                Une erreur est survenue. Veuillez vérifier tous les champs et
                re-essayer de nouveau.
            </p>
            <p>Si le problème persiste, n’hésitez pas à nous contacter.</p>
        </div>

        <form
            v-if="!success"
            action="/"
            class="c-form__inner"
            @submit.prevent="submitForm"
        >
            <template v-for="(input, i) in inputs">
                <div
                    :key="`input-${input.name}-${i}`"
                    class="c-form__el"
                    :class="[
                        { 'has-error': input.error },
                        { '-half': input.half },
                    ]"
                >
                    <app-select
                        v-if="input.options"
                        v-model="input.value"
                        :name="input.name"
                        :options="input.options"
                        :placeholder="input.label"
                        :required="!!input.required"
                        as="title:title"
                    />

                    <app-input-text
                        v-else
                        v-model="input.value"
                        :label="input.label"
                        :name="input.name"
                        :type="input.type"
                        :autocomplete="input.autocomplete"
                        :required="!!input.required"
                        :defaultValue="input.defaultValue"
                        :disabled="!!input.disabled"
                    />
                </div>
            </template>

            <div class="c-form__el -submit">
                <btn
                    :label="pending ? 'Envoi en cours...' : submitLabel"
                    type="submit"
                    color="dark"
                />
            </div>
        </form>

        <div
            v-else-if="successText || !!$slots.success"
            class="c-form__success"
        >
            <slot name="success">
                <h3 class="t-t3">{{ successText }}</h3>
            </slot>
        </div>

        <slot name="after" />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'
import AppSelect from 'objects/AppSelect'
import AppInputText from 'objects/AppInputText'

import Btn from 'components/Btn'

export default {
    name: 'AppForm',
    components: {
        AnimText,
        AppSelect,
        AppInputText,
        Btn,
    },
    props: {
        formName: false,
        formTemplate: {
            type: String,
            required: true,
        },
        inputs: {
            type: Array,
            default: () => [
                {
                    label: 'Prénom',
                    name: 'firstName',
                    type: 'text',
                    required: true,
                    autocomplete: 'given-name',
                    half: true,
                },
                {
                    label: 'Nom',
                    name: 'lastName',
                    type: 'text',
                    required: true,
                    autocomplete: 'family-name',
                    half: true,
                },
                {
                    label: 'Courriel',
                    name: 'fromEmail',
                    type: 'email',
                    disabled: false,
                    required: true,
                    autocomplete: 'email',
                    half: true,
                },
                {
                    label: 'Message',
                    name: 'body',
                    type: 'textarea',
                },
            ]
        },
        subject: {
            type: String,
            default: 'Nouvelle soumission de formulaire'
        },
        successText: {
            type: String,
            default: 'Votre demande a bien été envoyée',
        },
        submitLabel: {
            type: String,
            default: 'Soumettre',
        },
        toEmail: {
            type: String,
            default: null
        }
    },
    data: () => ({
        data: {
            message: {}
        },
        error: false,
        success: false,
        pending: false,
        exceptions: [
            'fromName',
            'fromEmail',
            'subject',
            'attachment[]'
        ]
    }),
    computed: {
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue,
        // contactFirstName() {
        //     return this.inputs.filter(i => i.name == 'firstName')[0].value
        // },
        // contactLastName() {
        //     return this.inputs.filter(i => i.name == 'lastName')[0].value
        // },
        contactFullName() {
            //return `${this.contactFirstName} ${this.contactLastName}`
            return this.inputs.filter(i => i.name == 'fullName')[0].value
        }
    },
    created() {
        this.reset()
    },
    methods: {
        submitForm() {

            if (this.pending) {
                return
            }

            // reset
            this.success = false
            this.error = false

            this.inputs.forEach((input, i) => {
                this.inputs[i].error = false
            })

            this.pending = true

            // Create form data
            const formData = new FormData()

            formData.append('action', 'contact-form/send')

            // Add hidden inputs
            formData.append(this.csrfName, this.csrfToken)
            formData.append('fromName', this.contactFullName)
            formData.append('message[template]', this.formTemplate)

            if (this.toEmail !== null)
                formData.append('toEmail', this.toEmail)

            if (this.subject) {
                formData.append('subject', this.subject)
            }
            if (this.formName) {
                formData.append('message[formName]', this.formName)
            }

            this.inputs.forEach((input, i) => {
                if (typeof input.value !== 'undefined') {

                    // Validation
                    if (input.value === '' && input.required) {
                        this.error = true
                        this.pending = false
                        this.inputs[i].error = true
                    }

                    // Prepend input to formData
                    if (!this.exceptions.includes(input.name)) {
                        //data.message[fieldName] = input.value
                        formData.append(`message[${input.name}]`, input.value)
                    } else {
                        //data[fieldName] = input.value
                        formData.append(input.name, input.value)
                    }
                }
            })

            if (!this.error) {
                fetch('/contact-form/send', {
                    method: 'POST',
                    body: formData
                })
                    .then(r => {

                        if (r.ok) {
                            return r.text()
                        } else {
                            this.pending = false
                            this.error = true
                            throw new Error('Something went wrong.')
                        }
                    })
                    .then(() => {

                        this.reset()

                        this.success = true
                        this.pending = false

                        this.$emit('success')
                    })
                    .catch(e => {
                        console.error('Request failed', e) // eslint-disable-line
                    })
            }


        },
        reset() {
            this.success = false
            this.inputs.forEach((input, i) => {
                input.value = ''
                this.inputs[i].error = false
            })
        }
    },
};

</script>

<style lang="scss">
.c-form {
    --input-height: 3.75em;

    --input-border-color: #{$color-dark};
    --input-border-radius: #{$border-radius-sm};

    --input-color-text: #{$color-dark};
    --input-color-bg: #{$color-green-light};
    --input-option-color-bg: #{$color-light};

    --input-disabled-color-text: #{$color-light};
    --input-disabled-color-bg: #{$color-light};

    --input-checked-color-text: #{$color-dark};
    --input-checked-color-bg: #{$color-light};

    padding: var(--grid-gutter);

    &.is-sending {
        cursor: wait;
        pointer-events: none;
        opacity: 0.8;
    }
}

.c-form__error {
    padding: 1em;
    margin-bottom: 1em;
    background: rgba(red, 0.06);
    border-radius: $border-radius-sm;
}

.c-form__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.c-form__heading {
    width: 100%;
    margin-bottom: 0.75em;

    &:not(:first-child) {
        margin-top: 2em;
    }
}

.c-form__el {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    &.has-error {
        --input-border-color: red;
    }

    &.-submit {
        display: flex;
        justify-content: flex-end;
    }

    @media #{md("md")} {
        &.-half {
            width: calc(50% - var(--grid-gutter-half));
        }
    }
}

.c-form__success {
}
</style>
