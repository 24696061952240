<template>
    <div
        :class="className"
    >
        <loader
            @animateOut="initReveal"
            ref="loader"
        />

        <app-header @toggleNav="toggleNav" ref="header" />

        <main class="l-main" ref="main">
            <router-view
                ref="view"
                :page="currentPage"
            />
        </main>

        <app-footer />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';

import Loader from 'objects/Loader';

import { mapState } from 'vuex'

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppFooter,
        Loader,
    },
    data: () => ({
        navIsOpen: false
    }),
    mounted() {
        this.isMobileOrTablet = window.innerWidth < 992

        window.addEventListener('resizeEnd', () => {
            this.isMobileOrTablet = window.innerWidth < 992
        })
    },
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            return classname
        },
    },
    methods: {
        initReveal() {
            this.$refs.header.$refs.logo.play()
            this.$refs.view.$refs.hero.reveal()
        },
        toggleNav(isOpen) {

            isOpen
                ? this.disableScroll()
                : this.enableScroll()

            this.navIsOpen = isOpen
        },

        // disable scroll when nav is open on tablet / mobile
        disableScroll() {
            if (this.isMobileOrTablet) {
                document.body.classList.add('-scroll-disabled')
            }
        },
        enableScroll() {
            document.body.classList.remove('-scroll-disabled')
        },
    }
}

</script>

<style lang="scss">

</style>
