<template>
    <component :is="tag" :class="className">
        <div class="l-grid-content__col -left">
            <div ref="left" class="l-grid-content__inner">
                <slot name="left" />
            </div>
        </div>
        <div class="l-grid-content__col -right">
            <slot name="right" />
        </div>
    </component>
</template>

<script>
export default {
    name: "GridContent",
    props: {
        tag: {
            type: String,
            default: "div",
        },
        sticky: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = "l-grid-content"

            if (this.sticky) {
                classname += " -sticky"
            }

            return classname
        },
    },
};
</script>

<style lang="scss">
.l-grid-content {
    display: grid;
    grid-gap: var(--grid-col-gap, var(--grid-gutter));

    --sticky-top: calc(var(--header-height) + var(--grid-gutter));

    @media #{md("md")} {
        --sticky-top: var(--header-height);
    }

    @supports (position: sticky) or (position: -webkit-sticky) {

        &.-sticky {
            > .l-grid-content__col >.l-grid-content__inner {
                position: -webkit-sticky;
                position: sticky;
                top: var(--sticky-top);

                @media #{md("md")} {
                    margin-bottom: calc( var(--grid-gutter) * 2);
                }
            }
        }
    }


    & + * {
        padding-top: var(--grid-gutter);
    }

    @media #{md("sm")} {
        grid-template-columns: var(--grid-col-left, 2fr) var(--grid-col-right, 3fr);
    }
    @media #{md("md")} {
        grid-template-columns: var(--grid-col-left, 1fr) var(--grid-col-right, 2fr);
    }
}
</style>
