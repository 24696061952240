/*
** GraphQL Configuration and variables
*/

export const api = '/api'

export const fetchApi = query => {
    // console.log('fetchApi:', query)

    return fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query })
        })
        .then(r => r.json())
        .then(r => r.data)
        // .then(r => {
        //     console.log('fetchApi response:', r)
        //     return r.json()
        // })
        // .then(r => {
        //     console.log('fetchApi json:', r)
        //     return r.data
        // })
        .catch(error => {
            console.error('fetchApi error:', error) // eslint-disable-line
            return undefined
        })
}


export const fetchJSON = url => {

    return fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(r => r.json())
        .then(r => r)
        .catch(error => {
            console.error('fetchJSON error:', error) // eslint-disable-line
            return undefined
        })
}
