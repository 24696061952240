<template>
    <div
        class="o-deco"
        :class="`-${position}`"
    >
        <span />
    </div>
</template>

<script>
export default {
    name: 'Deco',
    props: {
        position: {
            type: String,
            default: 'top',
            validator: (position) => {
                return ['top', 'bottom'].indexOf(position) !== -1
            }
        }
    }
}
</script>

<style lang="scss">
.o-deco {
    --deco-height: 6rem;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;

    height: var(--deco-height);
    mix-blend-mode: overlay;

    span {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('/static/images/texture-01.png');
        background-size: auto var(--deco-height);
        background-position: 0 -2rem;
        background-repeat: repeat-x;
    }

    &.-top {
        top: 0;
    }
    &.-bottom {
        bottom: 0;
        transform: rotate(180deg);
        transform-origin: center;
    }

    .-green-emerald &,
    .-green-pastel & {
        opacity: 0.5;
    }

    // Handle direct .grid children
    .grid > & {
        left: calc(var(--grid-start-x) * -1 + var(--grid-gutter-half));
        width: 100vw;
    }
}

</style>
