<template>
    <div>
        <app-hero
            :hero="hero"
            ref="hero"
            class="p-home__hero"
        >
            <template #afterContent>
                <div class="c-btn__group -vertical">
                    <btn
                        v-for="(button,i) in page.buttons"
                        :key="`home-button-${i}`"
                        :label="button.label"
                        :tag="button.tag"
                        :href="button.url"
                        :download="button.download"
                        :iconBefore="button.icon"
                        modifier="underline"
                        color="green-pastel"
                    />
                </div>
            </template>
            <template #media>
                <asset :asset="page.image" />
                <img src="/static/images/texture-waves-white.png" class="-custom-deco" />
            </template>
        </app-hero>

        <app-section v-if="page.solutions" class="p-home__solutions" color="green-light">
            <card-grid
                :cards="page.solutions"
                :slider="true"
                cardType="solution"
                :showIndex="true"
            >
                <template #beforeNav>
                    <h2 class="t-t3">Nos solutions</h2>
                </template>
                <template #afterNav>
                    <btn
                        label="Découvrir nos solutions"
                        modifier="underline"
                        tag="router-link"
                        :href="SECTION_HANDLES.SOLUTIONS|resolver"
                    />
                </template>
            </card-grid>

            <deco position="bottom" />

        </app-section>

        <app-section v-if="page.businessAreas"  class="p-home__business-areas">
            <div class="p-home__business-areas-header">
                <h3 class="t-t3">Nos domaines d'activité</h3>
                <btn
                    label="Découvrir nos services"
                    modifier="underline"
                    tag="router-link"
                    :href="SECTION_HANDLES.BUSINESS_AREAS|resolver"
                />
            </div>

            <div class="p-home__business-areas-cards">
                <card-business-area
                    v-for="(area, i) in page.businessAreas"
                    :key="`home-area-${i}`"
                    :element="area"
                />
            </div>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import AppHero from 'layout/AppHero';
import Asset from 'objects/Asset'
import Btn from 'components/Btn'
import CardGrid from 'components/CardGrid'
import CardBusinessArea from 'components/CardBusinessArea'

import Deco from 'objects/Deco'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Home',
    components: {
        Asset,
        AppSection,
        AppHero,
        Btn,
        CardGrid,
        CardBusinessArea,
        Deco
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    }),
    computed: {
        hero() {
            return {
                title: this.page.heading,
                description: this.page.description
            }
        }
    }
};

</script>

<style lang="scss">

.p-home {
    overflow-x: hidden;

    .l-hero, .l-header {
       --header-text-color: #{$color-green-emerald};
       --header-bg: #{$color-green-dark};
    }

    .l-section {
        --hero-after-bg: #{$color-green-light};
    }
}

.p-home__hero {

    .-custom-deco {
        position: absolute;
        z-index: 2;
        bottom: var(--grid-gutter-half);
        left: 0;
        right: 0;
        object-fit: cover;
        height: auto;
        width: 100%;

        @media #{md("sm")} {
            bottom: var(--grid-gutter);
        }
    }
}

.p-home__solutions {
    .o-deco {
        bottom: calc( var(--section-padding) * -1);
    }
}

.p-home__business-areas {

    &-header {
        padding-bottom: var(--grid-gutter);
        display: flex;
        flex-direction: column;

        .c-btn {
            width: fit-content;
        }

        > *:first-child {
            padding-bottom: var(--grid-gutter-half);
        }

        @media #{md("sm")} {
            justify-content: space-between;
            flex-direction: row;

            > *:first-child {
                padding-bottom: 0;
            }

        }
    }

    &-cards {
        --border-color: #{$color-green-emerald};
        display: grid;
        width: 100%;
        border-radius: $border-radius-sm;
        overflow: hidden;

        > *:first-of-type:after {
            content: '';
            height: 1px;
            width: 100%;
            background: var(--border-color);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            // border-bottom: 1px solid var(--border-color);
        }

        @media #{md("sm")} {
            grid-template-columns: 1fr 1fr;

            > *:first-of-type:after {
                // border-right: 1px solid var(--border-color);
                // border-bottom: 0;

                height: 100%;
                width: 1px;
                top: 0;
                left: auto;
                right: 0;
            }
        }
    }
}

</style>
