<template>
    <router-link to="/" class="o-logo" ref="logo">
        <span
            class="o-logo__lottie"
            :class="className + ' ' + containerClass"
            ref="logoSymbol"
        ></span>
        <icon icon="solia-logo-without-symbol" />
    </router-link>
</template>

<script>
import Icon from 'objects/Icon'
import lottie from 'lottie-web'
import logoAnimation from '../../assets/animations/logo-hover/data.json'

export default {
    name: 'AppLogo',
    props: {
        className: {
            type: String,
            required: true
        },
    },
    data: () => ({
        animation: null,
        logo: null,
        logoSymbol: null
    }),
    components: { Icon },
    mounted() {
        this.logo = this.$refs.logo.$el || document.querySelector('.o-logo')

        this.logoSymbol = this.$refs.logoSymbol.$el || document.getElementsByClassName(this.containerClass)[0]
        this.animation = this.animLogo()

        // Reverse the animation direction when a loop is complete
        this.animation.addEventListener('loopComplete', () => {
            this.animation.playDirection *= -1
        })

        this.logo.addEventListener('mouseover', () => {
            this.animation.loop = true
            this.animation.play()
        })
        this.logo.addEventListener('mouseout', () => {
            this.animation.loop = false
        })
        this.logo.addEventListener('click', () => {
            this.animation.loop = false
            this.animation.play()
        })
    },
    computed: {
        // The element targetted should be unique
        containerClass() {
            return this.className + '-' + this._uid
        }
    },
    methods: {
        animLogo() {
            const params = {
                container: this.logoSymbol,
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: logoAnimation
            }

            return lottie.loadAnimation(params)
        },
        play() {
            this.animation.loop = false
            this.animation.goToAndPlay(0, true)
        }
    }
}
</script>

<style lang="scss">
.o-logo {
    &__lottie {
        position: absolute;
        top: 0.1rem;
        left: 0rem;
        width: 1.5em;
        svg path {
            fill: currentColor;
        }
    }
}
</style>
