<template>
    <div class="c-grid-masonry">
        <masonry
            :cols="{default: 2, 1000: 1, 767: 2, 520: 1}"
            :gutter="{default: '3.75rem', 1100: '2.5rem'}"
        >
            <div
                class="card-masonry__wrapper"
                v-for="(card, i) in cards"
                :key="`card-grid-${i}`"
            >
                <card-article
                    :element="card"
                    class="card-masonry__item"
                />
            </div>
        </masonry>
    </div>
</template>

<script>
import CardArticle from 'components/CardArticle'

export default {
    name: 'GridMasonry',
    components: {
        CardArticle
    },
    props: {
        cards: {
            type: Array,
            default: () => [],
        }
    },
    data: () => ({
    }),
    created() {
    },
    computed: {
    },
    methods: {
    },
};

</script>

<style lang="scss">

.card-masonry {
    &__wrapper {
        margin-bottom: var(--grid-gutter);
    }
}
</style>

