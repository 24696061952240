<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :title="href !== null ? (title ? title : label) : false"
        :target="tag == 'a' ? '_blank' : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :class="className"
    >
        <span class="c-btn__inner">
            <icon
                v-if="iconBefore !== null"
                class="c-btn__icon"
                :icon="iconBefore"
                :bg-color="bgColor"
            />
            <span class="c-btn__label">
                <slot>{{ label }}</slot>
            </span>
            <icon
                v-if="iconAfter !== null"
                class="c-btn__icon"
                :icon="iconAfter"
                :bg-color="bgColor"
            />
        </span>
    </tag>
</template>

<script>

import Icon from 'objects/Icon'

export default {
    name: 'Btn',
    components: {
        Icon,
    },
    props: {
        color: {
            type: String,
            default: null,
            validator: (color) => {
                return ['dark', 'light', 'green-emerald', 'green-light', 'green-pastel', 'orange', 'fb-blue'].indexOf(color) !== -1
            }
        },
        bgColor: {
            type: String,
            default: null
        },
        tag: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        label: {
            type: String
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
        modifier: {
            type: String,
            default: 'boxed',
            validator: (modif) => {
                return ['boxed', 'underline', 'border'].indexOf(modif) !== -1
            }
        },
        iconModifier: {
            type: String,
            default: 'round'
        }
    },
    computed: {
        className() {
            let classname = 'c-btn'

            if (this.modifier) {
                classname += ` -${this.modifier}`
            }

            if (this.color) {
                classname += ` -${this.color}`
            }

            if (this.tag !== 'a' && this.tag !== 'router-link' && this.tag !== 'button') {
                classname += ' -no-link'
            }

            if (this.iconModifier === 'squared') {
                classname += ' -icon-squared'
            }

            return classname
        },
    },
}
</script>

<style lang="scss">


%btn_inner {
    z-index: 0;
    display: inline-flex;
    align-items: center;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    color: var(--btn-text-color);
    border-radius: $border-radius-sm;
    overflow: hidden;

    &:before {
        @include pseudo-el;
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--btn-bg-color);
        border-radius: inherit;
        transition: transform 0.8s $out-expo;
    }

    // For hover effect
    &:after {
        @include pseudo-el(
            $height: 100%,
            $width: 100%,
            $bg: var(--btn-hover-bg-color)
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform: translateY( calc(100% + 1px));
        transition: transform 0.4s $out-expo;
    }

    transition: color 0.4s $out-expo;

    &:hover {
        color: var(--btn-hover-text-color, --btn-text-color);
        transition: color 0.7s $out-expo;

        &:after {
            transform: translateY(0);
            transition: transform 0.8s $out-expo;
        }
    }
}

.c-btn {
    --btn-padding-y: 0.75em;
    --btn-padding-x: 1em;
    --btn-text-color: currentColor;
    --btn-bg-color: transparent;
    --btn-border-color: #{$color-dark};
    --btn-font-size: #{fs("regular")};

    display: inline-block;
    cursor: pointer;
    font-size: var(--btn-font-size);

    &.-no-link {
        pointer-events: none;
        cursor: default;
        --btn-padding-y: 0.625em;
        --btn-padding-x: 0.625em;
        --btn-bg-color: transparent;

        .c-btn__inner {
            padding: 0;
        }
    }

    &.-boxed {
        &.-dark {
            --btn-text-color: #{$color-green-light};
            --btn-bg-color: #{$color-dark};

            --btn-hover-bg-color: #{$color-green-emerald};
            --btn-hover-text-color: #{$color-dark};
        }
        &.-green-emerald {
            --btn-bg-color: #{$color-green-emerald};

            --btn-hover-bg-color: #{$color-green-light};
        }
        &.-light {
            --btn-bg-color: #{$color-light};

            --btn-hover-bg-color: #{$color-green-pastel};
        }
        &.-green-light {
            --btn-bg-color: #{$color-green-light};
            --btn-hover-bg-color: #{$color-green-pastel};
        }
        &.-green-pastel {
            --btn-bg-color: #{$color-green-pastel};
        }
        &.-orange {
            --btn-bg-color: #{$color-orange};
        }
        &.-fb-blue {
            --btn-bg-color: rgba(98, 179, 253, 1);
        }
    }
    &.-border {
        --btn-bg-color: transparent;
        --btn-hover-bg-color: #{$color-dark};
        --btn-hover-text-color: #{$color-light};
        .c-btn__inner {
            border: 1px solid var(--btn-text-color);
        }

        &:hover {
            .c-btn__inner {
                border-color: var(--btn-hover-bg-color);
            }
        }
    }

    &.-underline {
        --btn-padding-x: 0.125em;
        --btn-padding-y: 0.5em;
        --btn-hover-bg-color: #{$color-green-light};
        border-bottom: solid 2px currentColor;
        border-radius: 0;

        .c-btn__inner {
            border-radius: 0;
        }
    }

    &.-icon-squared {
        .o-icon {
            --svg-width: 3em;
            --svg-ratio: 2/3;
            border-radius: $border-radius-sm;
        }
    }

    .t-cms & {
        @extend %btn_inner;
    }
}

.c-btn__inner {
    @extend %btn_inner;
}

.c-btn__label {
    & + .c-btn__icon {
        margin-left: var(--btn-padding-x);
    }
}

.c-btn__icon {
    flex-shrink: 0;

    &:not(.-has-bg) {
        --svg-width: 2.25em;
        margin: -.5em;

        &.-facebook { margin: -.5em -.75em }
        &.-instagram { padding-top: 1px }
    }

    & + .c-btn__label {
        margin-left: calc( var(--btn-padding-x) - 1px );
    }
}

.c-btn__group {
    --btns-gap: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: var(--btns-gap);

    &.-vertical {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--btns-gap);

        > * {
            width: fit-content;
        }
    }
}
</style>
