<template>
    <span class="o-copyright | t-regular">
        {{ year }}, Sol-IA. Tous droits réservés.<br>
        Crédits&nbsp;: <a class="mambomambo-link" target="_blank" href="https://mambomambo.ca" title="Design et développement web par MamboMambo">MamboMambo</a>
    </span>
</template>

<script>

export default {
    name: 'Copyright',
    computed: {
        year: () => new Date().getFullYear()
    }
}
</script>

<style lang="scss">
.mambomambo-link {
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;

    &:after, &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        bottom: 0;
        left: 0;
        transform-origin: left;
        transform: scaleX(100%);
        transition: transform 0.6s $out-sine;
    }
    &:before {
        transform: translateX(-100%) scaleX(0);
        transform-origin: right;
        transition-duration: .8s;
    }

    &:hover {
        &:after {
            transform-origin: right;
            transform: scaleX(0);
        }

        &:before {
            transform-origin: left;
            transform: translateX(0) scaleX(100%);
        }
    }
}
</style>
