<template>
    <div class="c-btn__group">
        <btn
            v-if="facebook"
            label="Facebook"
            :href="facebook"
            tag="a"
            modifier="border"
            icon-before="facebook"
            target="_blank"
        />
        <btn
            v-if="linkedIn"
            label="LinkedIn"
            :href="linkedIn"
            tag="a"
            modifier="border"
            icon-before="linkedin"
            target="_blank"
        />
        <btn
            v-if="instagram"
            label="Instagram"
            :href="instagram"
            tag="a"
            modifier="border"
            icon-before="instagram"
            target="_blank"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import Btn from 'components/Btn'

export default {
    name: 'SocialMedias',
    components: {
        Btn
    },
    computed: {
        ...mapState({
            facebook: state => state.global.settings.facebook,
            linkedIn: state => state.global.settings.linkedIn,
            instagram: state => state.global.settings.instagram,
        })
    }
}
</script>
