<template>
    <div>
        <app-hero
            :hero="hero"
            ref="hero"
            class="p-solutions__hero"
        >
            <template #media>
                <asset :asset="page.image" />
            </template>
        </app-hero>

        <app-section class="p-solutions__content">
            <grid-content
                :sticky="true"
                v-for="(solution, i) in page.children"
                :id="solution.slug"
                :key="`p-solution${i}`"
            >
                <template #left>
                    <div class="t-content">
                        <tag
                            v-if="solution.inDevelopment"
                            label="En développement"
                        />
                        <h2
                            v-html="solution.title"
                            class="-title | t-t3"
                        />
                        <p
                            v-if="solution.description"
                            v-html="solution.description"
                            class="-description | t-large"
                        />
                        <div
                            v-if="solution.tags.length"
                            class="-tags"
                        >
                            <span class="t-bold t-large">Comprend</span>
                            <nav class="c-btn__group -vertical">
                                <btn
                                    v-for="(tag, i) in parseTags(solution.tags)"
                                    :key="`solution-tag-${i}`"
                                    :label="tag.label"
                                    tag="li"
                                    :iconBefore="tag.icon"
                                    bgColor="green-pastel"
                                />
                            </nav>
                        </div>
                    </div>
                </template>

                <template #right>
                    <boxed-content>
                        <asset
                            :asset="solution.image"
                            class="-featured-image"
                        />
                        <builder :blocks="solution.layout" />
                    </boxed-content>
                </template>

            </grid-content>
        </app-section>
    </div>
</template>

<script>
import {parseTags} from 'src/store/parse'

import AppSection from 'layout/AppSection'
import AppHero from 'layout/AppHero'
import GridContent from 'layout/GridContent'
import BoxedContent from 'layout/BoxedContent'

import Asset from 'objects/Asset'
import Tag from 'objects/Tag'
import Btn from 'components/Btn'

import Builder from 'builder/Builder'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Solutions',
    components: {
        AppSection,
        AppHero,
        Asset,
        BoxedContent,
        Btn,
        Builder,
        GridContent,
        Tag
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        parseTags
    }),
    computed: {
        hero() {
            return {
                title: this.page.heading || this.page.title,
                description: this.page.description
            }
        }
    }
};

</script>

<style lang="scss">
.p-solutions {
    .l-header,
    &__hero {
        --header-bg: #{$color-dark};
        --header-text-color: #{$color-green-emerald};
    }

    @media #{md("sm", "max")} {
        overflow-x: hidden;
    }
}

.p-solutions__content {

    .-description {
        max-width: 18em;
    }

    .-tags {
        .c-btn__group {
            margin-top: 1em;
        }
    }

    .l-grid-content {

        @media #{md("sm")} {
            --grid-col-gap: 0;

            .l-grid-content__col.-left {
                padding-right: var(--grid-gutter);
            }
        }


        .l-grid-content__col.-right {
            .-featured-image {
                margin: calc( var(--grid-gutter) * -1 );

                & + * {
                    margin-top: calc(var(--grid-gutter) * 2);
                }
            }
        }
    }

    .l-grid-content__inner {
        .t-content {
            --text-margin-top: var(--grid-gutter-half);
            --btn-margin-top: 0;

            * + .-tags {
                margin-top: var(--grid-gutter);
            }
        }


        .-tags {
            @media #{md("xs", "max")} {

                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                    width: var(--grid-gutter-half);
                    background: red;
                }

                &:before {
                    left: calc(var(--grid-gutter-half) * -1);
                    background: linear-gradient(to right, #FFF, rgba(255, 255, 255, 0));
                }
                &:after {
                    right: calc(var(--grid-gutter-half) * -1);
                    background: linear-gradient(to left, #FFF, rgba(255, 255, 255, 0));
                }

                .c-btn__group {
                    width: 100vw;
                    margin-left: calc( var(--grid-gutter-half) * -1);
                    margin-right: calc( var(--grid-gutter-half) * -1);
                    padding-left: var(--grid-gutter-half);
                    overflow-x: scroll;
                    grid-auto-flow: column;
                    grid-auto-columns: max-content;
                    grid-template-columns: unset; // do not set template columns and rows
                    grid-template-rows: unset;


                    & > * {
                        width: max-content;

                        &:last-child {
                            padding-right: var(--grid-gutter-half);
                        }
                    }
                }
            }
        }
    }
}
</style>
