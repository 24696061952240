/*
** Debounce function
*/
export const debounce = (callback, delay = 250) => {
    let timeoutId
    return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}


/*
** Round number with n decimals
*/

export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
** Reduced motion var
*/

export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches


/*
** Add resizEnd event to window
*/

export const resizeEnd = () => {
    const resizeEnd = new Event('resizeEnd');

    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEnd)
    }, 200));
}


export const isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}


export const getFilename = (url) => {
    if (!url) return
    return url.substring(url.lastIndexOf('/')+1)
}
