<template>
    <div class="p-blog">
        <app-section class="p-blog__posts">
            <grid-content class="p-blog__posts--container" :sticky="true">
                <template #left>
                    <app-hero
                        :hero="hero"
                        ref="hero"
                        heading="t-t3"
                        :gap="false"
                        class="p-blog__header"
                    >
                        <template #afterContent>
                            <h3 class="t-bold t-large">Filtrer</h3>
                            <div
                                class="p-blog__filters | c-btn__group -vertical"
                            >
                                <btn
                                    v-for="(filter, i) in filters"
                                    :key="`p-blog__filter-${i}`"
                                    :label="filter.label"
                                    :modifier="filter.modifier || 'underline'"
                                    :color="filter.color || 'light'"
                                    @click.native="
                                        setActiveFilter(filter.handle)
                                    "
                                    :class="{
                                        '-is-selected':
                                            filter.handle === activeFilter,
                                    }"
                                />
                                <btn
                                    label="Tout"
                                    modifier="underline"
                                    color="light"
                                    @click.native="reset()"
                                    :class="{
                                        '-is-selected': activeFilter === null,
                                    }"
                                />
                            </div>
                        </template>
                    </app-hero>
                </template>

                <template #right>
                    <grid-masonry
                        :cards="filteredCards"
                        class="p-blog__posts--list"
                        :class="{ '-loading': loading }"
                    />
                    <div v-if="totalPages > 1" class="p-blog__pagination">
                        <btn
                            label="Page précédente"
                            modifier="underline"
                            @click.native="prev()"
                            class="p-blog__page--prev"
                            :class="{
                                '-is-disabled': activePage === 1,
                            }"
                        />
                        <div class="p-blog__pages">
                            <btn
                                v-for="page in totalPages"
                                :key="`p-blog-pageIndex-${page}`"
                                :label="page.toString()"
                                modifier="underline"
                                class="p-blog__page--index"
                                @click.native="selectActivePage(page)"
                                :class="{
                                    '-is-active-page': page == activePage,
                                }"
                            />
                        </div>
                        <btn
                            label="Page suivante"
                            modifier="underline"
                            class="p-blog__page--next"
                            @click.native="next()"
                            :class="{
                                '-is-disabled': activePage === totalPages,
                            }"
                        />
                    </div>
                </template>
            </grid-content>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection'
import AppHero from 'layout/AppHero'
import GridContent from 'layout/GridContent'
import Btn from 'components/Btn'
import GridMasonry from 'components/GridMasonry'

import { SECTION_HANDLES } from 'src/constants'
import { isEquivalent } from 'src/utils'

export default {
    name: 'Blog',
    components: {
        AppSection,
        AppHero,
        Btn,
        GridContent,
        GridMasonry
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        ready: false,
        loading: false,
        activeFilter: null,
        activePage: 1,
        cardsPerPage: 4,
        filters: {},
        posts: []
    }),
    created() {
        this.updateFromUrl()

        this.$store.dispatch('articles/preloadAll').then(articles => {

            // Assign articles
            this.posts = articles

            // Build filters from results
            let _filters = {}
            articles.map(article => {
                _filters[article.category.handle] = article.category
            })

            this.filters = _filters
        })
    },
    computed: {
        hero() {
            return {
                title: this.page.heading,
                description: this.page.description
            }
        },
        filteredCards() {

            let posts = this.posts,
                filteredCards = []

            if (!this.activeFilter)
                posts = this.posts
            else
                posts = this.posts.filter(p => {
                    return p.type === this.activeFilter
                })

            filteredCards = posts.slice(
                (this.activePage - 1) * this.cardsPerPage,
                (this.activePage) * this.cardsPerPage
            )

            return filteredCards
        },
        totalPages() {

            if (this.activeFilter) {
                let total = this.posts.filter(com => com.category === this.activeFilter)
                return Math.ceil(total.length / this.cardsPerPage)
            }
            else return Math.ceil(this.posts.length / this.cardsPerPage)
        }
    },
    methods: {
        setActiveFilter(type) {
            this.loading = true
            this.activeFilter = type
            this.activePage = 1
        },
        selectActivePage(page) {
            this.loading = true
            this.activePage = page
        },
        next() {
            if (this.activePage < this.totalPages) {
                this.activePage++
            }
        },
        prev() {
            if (this.activePage > 1) {
                this.activePage--
            }
        },
        scrollTop() {
            window.scrollTo(0, 0)
        },
        updateFromUrl() {

            // Escape if empty
            if (Object.keys(this.$route.query).length === 0) {
                this.ready = true
                return
            }

            const query = this.$route.query

            if (query.categorie)
                this.setActiveFilter(query.categorie)

            if (query.page)
                this.selectActivePage(query.page)

            // Prevent updateUrl() method execution while setActiveFilter() and selectActivePage() are working
            setTimeout(() => {
                this.ready = true
                this.loading = false
            }, 200)

        },
        updateUrl() {

            if (!this.ready)
                return

            this.scrollTop()
            this.loading = true

            let query = {}

            if (this.activeFilter !== null)
                query['categorie'] = this.activeFilter

            if (this.activePage != 1)
                query['page'] = this.activePage

            if (Object.keys(query).length && !isEquivalent(this.$route.query, query)) {
                this.$router.replace({ query })
                    .then(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 200)
                    })
                    .catch(() => { })
            } else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: undefined
                })
                    .then(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 200)
                    })
                    .catch(() => { })
            }
        },
        reset() {
            this.activeFilter = null
            this.activePage = 1
        }
    },
    watch: {
        activePage() {
            this.updateUrl()
        },
        activeFilter() {
            this.updateUrl()
        }
    }
};

</script>

<style lang="scss">
html {
    scroll-behavior: smooth;
}
.p-blog {
    &__header {
        @media #{md("sm")} {
            padding-top: var(--grid-gutter);
        }

        .l-hero__text {
            padding-top: 0;
            padding-left: 0;

            .l-hero__text--after {
                margin-top: var(--grid-gutter-half);
                padding-bottom: var(--grid-gutter);
            }
        }
    }

    &__posts {
        padding-top: 0;

        &--container {
            grid-gap: 0;

            // Fix sticky top when not enough article-card after filter
            .l-grid-content__inner {
                @media #{md("md")} {
                    top: 0;
                    margin-top: var(--header-height);
                }
            }

            .l-grid-content__col.-left {
                margin-right: var(--grid-gutter-half);
            }

            .l-grid-content__col.-right {
                padding-top: 0;

                @media #{md("sm")} {
                    padding-top: calc(
                        var(--header-height) + var(--grid-gutter-half)
                    );
                }
                @media #{md("md")} {
                    padding-top: var(--grid-gutter-half);
                }
            }
        }

        .c-btn__inner {
            padding: var(--btn-padding-y) var(--btn-padding-x) !important;
        }
    }

    &__filters {
        --btns-gap: 1.5rem;
        margin-top: 1rem;

        // Selected Button
        .c-btn.-is-selected {
            .c-btn__inner {
                &:after {
                    transform: translateY(0);
                }
            }
        }
    }

    &__posts--list {
        transition: opacity 1s $out-quad;

        &.-loading {
            opacity: 0;
            transition: opacity 0s $out-quad;
        }

        @media #{md("xs", "max")} {
            > * {
                justify-self: center;
                margin: 0 var(--grid-gutter-half);
            }
        }
    }
}

.p-blog__pagination {
    display: flex;
    justify-content: space-between;
    margin: var(--grid-gutter) var(--grid-gutter-half);

    .p-blog__pages {
        > * + * {
            margin-left: var(--grid-gutter-half);
        }
    }

    .-is-active-page {
        .c-btn__inner:after {
            transform: translateY(0);
        }
    }

    .c-btn.-is-disabled {
        pointer-events: none;
        opacity: 0.7;
        color: gray;
    }
}
</style>
