<template>
    <slider
        v-if="data.images.length"
        :slides="data.images"
        ref="slider"
        :fullheight="true"
        :prevNext="true"
        :show-index="true"
        :zoom="true"
        modifier="gallery"
        class="b-gallery"
        :options="{
            wrapAround: true,
            draggable: false,
        }"
    >
        <template #slide="image">
            <asset :asset="image" :rounded="true" @loaded="refreshSlider()" />
        </template>
    </slider>
</template>

<script>
import Asset from 'objects/Asset'
import Slider from 'components/Slider'
import Icon from 'objects/Icon'

export default {
    name: 'BlockGallery',
    components: {
        Icon,
        Asset,
        Slider,
    },
    props: {
        data: false
    },
    methods: {
        refreshSlider() {
            this.$refs.slider.resize()
        }
    }
}
</script>

<style lang="scss">
.b-gallery {
    display: flex;
    flex-direction: column-reverse;

    .c-slider__side {
        justify-content: flex-end;
        margin: var(--grid-gutter-half) var(--grid-gutter) 0;
    }

    .c-slider__btn,
    .c-slide__count {
        --nav-bg: #{$color-light};
    }

}
</style>
