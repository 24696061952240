/*
 * Craft GraphQL Snippets
 */

import getAsset from './assets'

/**********************************
 *
 *
 *  DEFAULT
 *
 *
 **********************************/

// Default data
const defaultData = `
id
uri
`

// Default entry
const entry = `
id
section: sectionHandle
title
slug
uri
`

// SEO
const seo = `
seo {
    title
    description
}
`

// Hero
const hero = `
heading
description
${getAsset('image', 'hero')}
`

// Icon Picker
const icon = `
icon {
    name: sprite
}
`

// Link (Link field)
const link = `
target {
    text
    url
    type
    target
    element {
        ...on EntryInterface {
            ${entry}
        }
    }
}
`

const tags = `
tags {
    ...on tags_BlockType {
        ${icon}
        label
    }
}
`

// Simple button
const button = `
    ${icon}
    ${link}
`

// Buttons (SuperTable)
const buttons = `
buttons {
    ...on buttons_BlockType {
        ${button}
    }
}
`

// Map (SuperTable)
const map = `
map {
    ...on map_BlockType {
        lng
        lat
    }
}
`

// Activities (SuperTable)
const activities = `
activities {
    ...on activities_BlockType {
        ${icon}
        ${getAsset('image', 'activity.card')}
        label
    }
}
`

// Activities (SuperTable)
const team = `
team {
    ...on team_BlockType {
        ${getAsset('image', 'team.card')}
        name: fullName
        role
        email
    }
}
`

/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

// Accordions
const blockAccordions = `
...on layout_BlockAccordions_BlockType {
    typeHandle
    openFirst: trueOrFalse
    accordions: children {
        ...on layout_accordion_BlockType {
            ${icon}
            heading
            subtitle: heading2
            content: wysiwyg
            ${buttons}
        }
    }
}
`

// Content
const blockContent = `
...on layout_BlockContent_BlockType {
    typeHandle
    content: body2
    padded: trueOrFalse2
    columns: trueOrFalse3
}
`

// Content and Image
const blockContentImage = `
...on layout_BlockContentImage_BlockType {
    typeHandle
    ${getAsset('image', 'builder.contentImage')}
    content: body2
}
`

// Gallery
const blockGallery = `
...on layout_BlockGallery_BlockType {
    typeHandle
    ${getAsset('images', 'solution.gallery')}
}
`

// Heading
const blockHeading = `
...on layout_BlockHeading_BlockType {
    typeHandle
    heading
}
`

// Image
const blockImage = `
...on layout_BlockImage_BlockType {
    typeHandle
    ${getAsset()}
}
`

// Spacer
const blockSpacer = `
...on layout_BlockSpacer_BlockType {
    typeHandle
}
`

// Testimonies
const blockTestimonies = `
...on layout_BlockTestimonies_BlockType {
    typeHandle
    testimonies: children {
        ...on layout_testimony_BlockType {
            ${getAsset('image', 'testimony')}
            name: heading
            role: heading2
            quote: description
        }
    }
}
`

// Boxed
const blockBoxedContent = `
    ...on layout_BlockBoxedContent_BlockType {
        typeHandle
        color
        blocks: children {
            ${blockAccordions}
            ${blockContent}
            ${blockContentImage}
            ${blockGallery}
            ${blockHeading}
            ${blockImage}
            ${blockSpacer}
        }
    }
`

// Layout
const layout = `
layout {
    ${blockAccordions}
    ${blockBoxedContent}
    ${blockContent}
    ${blockContentImage}
    ${blockGallery}
    ${blockHeading}
    ${blockImage}
    ${blockSpacer}
    ${blockTestimonies}
}
`

/**********************************
 *
 *
 *  CHANNELS AND STRUCTURES
 *
 *
 **********************************/

//
const servicesPreload = `
id
title
uri
...on services_services_Entry {
    shortTitle: heading
}
`

const services = `
${entry}
...on services_services_Entry {
    shortTitle: heading
    ${icon}
    color
    ${getAsset('images', 'services.gallery')}
    ${buttons}
    ${layout}
}
`

//
const businessAreasPreload = `
${entry}
...on businessAreas_businessAreas_Entry {
    ${icon}
    ${getAsset('image', 'businessArea.card')}
}
`
// getAsset Doesnt work
const businessAreas = `
${entry}
...on businessAreas_businessAreas_Entry {
    description
    ${layout}
    services: selectEntriesServices {
        ${servicesPreload}
    }
}
`

//
const solutionsPreload = `
${entry}
...on solutions_solutions_Entry {
    description
    inDevelopment: trueOrFalse
    ${tags}
    ${getAsset('image', 'solution.card')}
}
`

const solutions = `
${entry}
...on solutions_solutions_Entry {
    description
    inDevelopment: trueOrFalse
    ${tags}
    ${getAsset('image', 'solution.single')}
    ${layout}
}
`

//
const articlesPreload = `
${entry}
type: typeHandle
datePost: postDate @formatDateTime
...on articles_pressArticle_Entry {
    ${getAsset('image', 'article.card')}
    excerpt: description
}
...on articles_pressReleases_Entry {
    ${getAsset('image', 'article.card')}
    excerpt: description
}
...on articles_event_Entry {
   eventDate: date @formatDateTime
   excerpt: description
}
 `

const articles = `
${entry}
type: typeHandle
datePost: postDate @formatDateTime
...on articles_pressArticle_Entry {
    ${getAsset('image', 'article.card')}
    imageCover: ${getAsset('image', 'article.single')}
    excerpt: description
    body: wysiwyg
    ${seo}
}
...on articles_pressReleases_Entry {
    ${getAsset('image', 'article.card')}
   imageCover: ${getAsset('image', 'article.single')}
   excerpt: description
   body: wysiwyg
   ${seo}
}
...on articles_event_Entry {
   eventDate: date @formatDateTime
   excerpt: description
   body: wysiwyg
   ${seo}
}
 `

//
export default {
    defaultData,
    entry,
    hero,
    seo,
    tags,
    buttons,
    map,
    activities,
    team,
    layout,

    //
    servicesPreload,
    services,
    businessAreasPreload,
    businessAreas,
    solutionsPreload,
    solutions,
    articlesPreload,
    articles
}
