/*
** Data parser
*/

import { SECTION_HANDLES } from 'src/constants'
import { getFilename } from 'src/utils'

// Utilities
const arrayHasItem = array => {
    return typeof array !== 'undefined' && array.length > 0
}

export const parseIcon = icon => {
    return icon && icon.name ? icon.name.replace('svg-', '') : null
}

export const parseButtons = buttons => {
    let _buttons = []

    if (!arrayHasItem(buttons))
        return

    buttons.forEach(b => {

        if (b.target.type == 'entry' && !b.target.element.uri)
            return

        if (b.target.type != 'entry' && !b.target.url)
            return

            _buttons.push({
                icon: parseIcon(b.icon),
                label: b.target.text,
                tag: b.target.type == 'entry' ? 'router-link' : 'a',
                target: b.target.target,
                url: b.target.type == 'entry' ? `/${b.target.element.uri}` : b.target.url,
                download: b.target.type == 'asset' ? getFilename(b.target.url) : null
            })

    })
    return _buttons
}

export const parseTags = tags => {
    let _tags = []

    if (!arrayHasItem(tags))
        return

    tags.forEach(tag => {
        _tags.push({
            icon: tag.icon.name ? tag.icon.name.replace('svg-', '') : null,
            label: tag.label
        })
    })
    return _tags
}

// Parse method
export const parseData = (type, data) => {

    // Format section handle properly
    data.section = data.section.replace('Single', '')

    // Standard single asset image
    if (data.image)
        data.image = arrayHasItem(data.image) ? data.image[0] : null

    // Clean buttons
    if (data.buttons)
        data.buttons  = parseButtons(data.buttons)

    switch(type) {
        case(SECTION_HANDLES.HOME): {

            // Solutions
            data.solutions.forEach(solution => {
                if (arrayHasItem(solution.image)) {
                    solution.image = solution.image[0]
                }
                solution.tags = parseTags(solution.tags)
            })

            // Solutions
            data.businessAreas.forEach(a => {
                if (arrayHasItem(a.image)) {
                    a.image = a.image[0]
                }
                a.icon = parseIcon(a.icon)
            })

            break
        }

        // ARTICLES
        case(SECTION_HANDLES.ARTICLES): {

            // Event
            if (data.type === 'event') {
                const eventDate = new Date(data.eventDate)
                data.eventDate = eventDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })
            }

            // Date
            if(data.datePost) {
                const postDate = new Date(data.datePost)
                data.date = postDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })
            }

            // Type
            const readableType = {
                pressReleases: 'Communiqué',
                pressArticle: 'Article de presse',
                event: 'Événement'
            }
            data.category = {
                handle: data.type,
                label: readableType[data.type]
            }

            break;
        }
    }

    return data
}
