<template>
    <i :class="className">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
        bgColor: {
            type: String,
            default: null,
            validator: (color) => {
                return ['green-light', 'green-emerald', 'orange', 'green-pastel', 'orange'].indexOf(color) !== -1
            }
        }
    },
    computed: {
        path() {
            return `/dist/svg/icon-sprites.svg#svg-${this.icon}`;
        },
        className() {
            let classname = `o-icon -${this.icon}`

            if(this.size) {
                classname += ` -${this.size}`
            }

            if (this.bgColor) {
                classname += ` -has-bg -${this.bgColor}`
            }

            return classname;
        }
    },
};

</script>

<style lang="scss">

.o-icon {

    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }

    &.-has-bg {
        --svg-width: 2.75em;

        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--svg-width);
        height: var(--svg-width);
        border-radius: 50%;

        &.-green-light {
            --svg-bg: #{$color-green-light};
        }
        &.-orange {
            --svg-bg: #{$color-orange};
        }

        &.-green-emerald {
            --svg-bg: #{$color-green-emerald};
        }

        &.-green-pastel {
            --svg-bg: #{$color-green-pastel};
        }

        background-color: var(--svg-bg);
    }
}

/*----------  SVG Sizes  ----------*/

.o-icon {
    --svg-ratio: 1/1;
    --svg-width: 1em;
}

.svg-solia-logo,
.svg-solia-logo-without-symbol {
   --svg-width: 8rem;
   --svg-ratio: 24/119;
}

.svg-solia-symbol {
   --svg-width: 3rem;
   --svg-ratio: 1/1;
}

</style>
