var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-blog"},[_c('app-section',{staticClass:"p-blog__posts"},[_c('grid-content',{staticClass:"p-blog__posts--container",attrs:{"sticky":true},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('app-hero',{ref:"hero",staticClass:"p-blog__header",attrs:{"hero":_vm.hero,"heading":"t-t3","gap":false},scopedSlots:_vm._u([{key:"afterContent",fn:function(){return [_c('h3',{staticClass:"t-bold t-large"},[_vm._v("Filtrer")]),_c('div',{staticClass:"p-blog__filters | c-btn__group -vertical"},[_vm._l((_vm.filters),function(filter,i){return _c('btn',{key:("p-blog__filter-" + i),class:{
                                    '-is-selected':
                                        filter.handle === _vm.activeFilter,
                                },attrs:{"label":filter.label,"modifier":filter.modifier || 'underline',"color":filter.color || 'light'},nativeOn:{"click":function($event){return _vm.setActiveFilter(filter.handle)}}})}),_c('btn',{class:{
                                    '-is-selected': _vm.activeFilter === null,
                                },attrs:{"label":"Tout","modifier":"underline","color":"light"},nativeOn:{"click":function($event){return _vm.reset()}}})],2)]},proxy:true}])})]},proxy:true},{key:"right",fn:function(){return [_c('grid-masonry',{staticClass:"p-blog__posts--list",class:{ '-loading': _vm.loading },attrs:{"cards":_vm.filteredCards}}),(_vm.totalPages > 1)?_c('div',{staticClass:"p-blog__pagination"},[_c('btn',{staticClass:"p-blog__page--prev",class:{
                            '-is-disabled': _vm.activePage === 1,
                        },attrs:{"label":"Page précédente","modifier":"underline"},nativeOn:{"click":function($event){return _vm.prev()}}}),_c('div',{staticClass:"p-blog__pages"},_vm._l((_vm.totalPages),function(page){return _c('btn',{key:("p-blog-pageIndex-" + page),staticClass:"p-blog__page--index",class:{
                                '-is-active-page': page == _vm.activePage,
                            },attrs:{"label":page.toString(),"modifier":"underline"},nativeOn:{"click":function($event){return _vm.selectActivePage(page)}}})}),1),_c('btn',{staticClass:"p-blog__page--next",class:{
                            '-is-disabled': _vm.activePage === _vm.totalPages,
                        },attrs:{"label":"Page suivante","modifier":"underline"},nativeOn:{"click":function($event){return _vm.next()}}})],1):_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }