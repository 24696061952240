/*
 * Articles
 */
import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

import Crud from '../crud';
const crud = new Crud('articles');

// State
const state = {
    ...crud.state
}

// Getters
const getters = {
    ...crud.getters
}

// Actions
const actions = {
    ...crud.actions,
    getSiblings(store, {id, date}) {

        id = parseInt(id)

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            fetchApi(queries.siblings(id, date))
            .then(r => {

                // Exit on error
                if (!r) {
                    throw new Error(`Siblings failed`)

                } else {
                    resolve(r)

                    // End load
                    store.dispatch('loader/endLoad', null, {root: true});
                }
            })
            .catch(e => {
                reject({
                    code: 404,
                    message: e.message
                })

                // End load
                store.dispatch('loader/endLoad', null, {root: true});
            })
        })
    }
}

// Mutations
const mutations = {
    ...crud.mutations
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
