<template>
    <tag
        :is="tag"
        :class="className"
    >
        <span class="o-tag__inner">
            <span class="o-tag__label">
                <slot>{{ label }}</slot>
            </span>
        </span>
    </tag>
</template>

<script>

export default {
    name: 'Tag',
    components: {
    },
    props: {
        tag: {
            type: String,
            default: 'span',
        },
        label: {
            type: String
        }
    },
    computed: {
        className() {
            let classname = 'o-tag'

            if (this.modifier) {
                classname += ` -${this.modifier}`
            }

            return classname
        },
    },
}
</script>

<style lang="scss">
.o-tag {
    --btn-padding-y: 0.5em;
    --btn-padding-x: 1em;
    --btn-text-color: #{$color-dark};
    --btn-bg-color: #{$color-green-pastel};
    --btn-font-size: #{fs("regular")};

    display: inline-block;
    font-size: var(--btn-font-size);
}

.o-tag__inner {
    display: inline-block;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    color: var(--btn-text-color);
    background-color: var(--btn-bg-color);
    border-radius: $border-radius-md;
}
</style>
