<template>
    <card
        modifier="solution"
        :class="{'-no-image': element.image.length === 0}"
    >
        <template #asset v-if="element.image.url">
            <asset
                :asset="element.image"
                :title="element.title"
                :rounded="false"
            />
        </template>
        <template #content>
            <h3 class="c-card__title | t-t3">{{ element.title }}</h3>
            <p>{{ element.description }}</p>
            <btn
                tag="router-link"
                :href="`/${element.uri}`"
                label="En savoir plus"
                :color="btnColor"
            />
        </template>
        <template #afterContent >
            <ul class="c-card__tags | c-btn__group -vertical">
                <btn
                    v-for="(tag, i) in tags"
                    :key="`card-solution-tag-${i}`"
                    :iconBefore="tag.icon"
                    :label="tag.label"
                    :bg-color="iconBgColor"
                    tag="li"
                />
            </ul>
        </template>
    </card>
</template>

<script>
import Btn from './Btn.vue';
import Card from './Card.vue';
import Asset from 'objects/Asset';

export default {
    name: 'CardSolution',
    components: {
        Btn,
        Asset,
        Card,
    },
    props: {
        btnColor: {
            type: String,
            default: 'light'
        },
        iconBgColor: {
            type: String,
            default: 'green-pastel'
        },
        element: {
            type: Object,
            required: true
        }
    },
    computed: {
        tags() {
            if (!this.element.tags)
                return

            return this.element.tags.slice(0,3)
        }
    }
};

</script>

<style lang="scss">

.c-card.-solution {

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;

    background: $color-green-light;
    box-shadow: 0px 0px 2em 0px rgba($color-green-dark, 0.1);
    transition: .6s ease;
    width: fit-content;


    border-radius: $border-radius-sm;
    overflow: hidden;

    @media #{md("md")} {
        flex-direction: row-reverse;
        justify-content: flex-end;

    }

    &.-no-image {
        max-width: fit-content;
        justify-content: flex-end;
    }

    &:hover {
        box-shadow: 0px 0px 2em 0px rgba($color-green-dark, 0.2);
    }

    .c-card__asset {
        max-width: 100%;
        .o-asset {
            height: 100%;
        }
        img {
            height: 100%;
            object-fit: cover;
        }

        @media #{md("md")} {
            .is-safari &, .is-mobile-safari & {
                max-width: 55%;
            }
        }
    }
    .c-card__content {
        padding: var(--grid-gutter);
        max-width: calc(300px + var(--grid-gutter) * 2);
    }

    .c-card__tags {
        margin-top: 2em;

        @media #{md("xs", "max")} {
            display: none;
        }
    }
}

</style>
