<template>
    <div>
        <app-hero
            :hero="hero"
            ref="hero"
            class="p-businessAreas__hero"
        >
            <template #media>
                <asset :asset="page.image" />
            </template>
        </app-hero>

        <app-section class="p-businessAreas__content">
            <grid-content
                v-for="(area, i) in page.children"
                :key="`area-${i}`"
                :id="area.slug"
                :sticky="true"
            >
                <template #left>
                    <div class="t-content">
                        <h2
                            v-html="area.title"
                            class="-title | t-t3"
                        />
                        <p
                            v-if="area.description"
                            v-html="area.description"
                            class="-description | t-large"
                        />
                        <div
                            v-if="area.services.length"
                            class="-buttons"
                        >
                            <span class="t-bold t-large">Champs d'expertise</span>
                            <nav class="c-btn__group -vertical">
                                <btn
                                    v-for="(service, i) in area.services"
                                    :key="`area-service-${i}`"
                                    modifier="underline"
                                    :label="service.shortTitle || service.title"
                                    tag="router-link"
                                    :href="`/${service.uri}`"
                                />
                            </nav>
                        </div>
                    </div>
                </template>

                <template #right>
                    <builder :blocks="area.layout" />
                </template>

            </grid-content>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection'
import AppHero from 'layout/AppHero'
import GridContent from 'layout/GridContent'

import Asset from 'objects/Asset'
import Btn from 'components/Btn'

import Builder from 'builder/Builder'

export default {
    name: 'BusinessAreas',
    components: {
        Asset,
        AppHero,
        AppSection,
        Btn,
        Builder,
        GridContent
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
    }),
    computed: {
        hero() {
            return {
                title: this.page.heading || this.page.title,
                description: this.page.description
            }
        }
    }
}

</script>

<style lang="scss">
.p-businessAreas {
    .l-header,
    &__hero {
        --header-bg: #{$color-green-light};
        --header-text-color: #{$color-dark};
    }
}

.p-businessAreas__content {

    .-description {
        max-width: 18em;
    }

    .-buttons {
        .c-btn__group {
            margin-top: 1em;
        }
    }

    .l-grid-content {

        @media #{md("sm")} {
            --grid-col-gap: 0;
        }

        .l-grid-content__col.-left {
            padding-right: var(--grid-gutter);
        }
    }

    .l-grid-content__inner {
        .t-content {
            --text-margin-top: var(--grid-gutter-half);
            --btn-margin-top: 0;

            * + .-buttons {
                margin-top: var(--grid-gutter);
            }
        }
    }

}
</style>
