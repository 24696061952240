<template>
    <card modifier="team">
        <template #asset>
            <asset :asset="element.image" />
        </template>

        <template #content>
            <h3 class="t-t4 -title">
                {{ element.name }}
            </h3>
            <p
                v-if="element.role"
                v-html="element.role"
            />
            <btn
                v-if="element.email"
                :label="element.email"
                color="light"
                tag="a"
                :href="`mailto:${element.email}`"
            />
            <deco position="bottom"/>
        </template>
    </card>
</template>

<script>

import Deco from 'objects/Deco'
import Asset from 'objects/Asset'
import Card from 'components/Card'
import Btn from 'components/Btn'

export default {
    name: 'CardTeam',
    components: {
        Asset,
        Card,
        Btn,
        Deco
    },
    data: () => ({
    }),
    props: {
        element: {
            type: Object,
            required: true
        }
    },
};

</script>

<style lang="scss">
.c-card.-team {
    --max-width: 100%;
    width: 100%;

    border-radius: $border-radius-sm;
    overflow: hidden;
    .c-card__content {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: var(--grid-gutter-half);
        background: linear-gradient(to top, $color-green-emerald, transparent);
    }

    .o-deco {
        //remove padding of c-card__content
        margin: calc(-1 * var(--grid-gutter-half));
        opacity: 0.5;
    }

    .-title {
        padding-right: 1em;
    }
}
</style>
