/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: 'hero',
        mode: 'crop',
        width: 920,
        height: 725,
        quality: 90,
        position: 'center-center',
        srcset: `["340w", "355w", "683w", "710w", "748w", "960w", "1366w", "1496w", "1920w"]`
    },
    {
        name: 'solution.card',
        mode: 'crop',
        width: 575,
        height: 515,
        quality: 90,
        position: 'center-center',
        srcset: `["320w", "335w", "421w", "546w", "640w", "670w", "842w", "1092w"]`
    },
    {
        name: 'solution.single',
        mode: 'crop',
        width: 750,
        height: 590,
        quality: 90,
        position: 'center-center',
        srcset: `["400w", "415w", "485w", "643w", "800w", "830w", "920w", "970w", "1286w", "1840w"]`
    },
     {
        name: 'solution.gallery',
        mode: 'resize',
        width: 514,
        height: null,
        quality: 90,
        position: 'center-center',
        srcset: `["252w", "265w", "325w", "358w", "486w", "504w", "530w", "571w" "650w", "716w", "972w", "1142w"]`
    },
    {
        name: 'businessArea.card',
        mode: 'crop',
        width: 560,
        height: 304,
        quality: 90,
        position: 'center-center',
        srcset: `["240w", "255w", "283w", "360w", "480w", "510w", "566w", "720w", "960w"]`
    },
    {
        name: 'testimony',
        mode: 'crop',
        width: 91,
        height: 91,
        quality: 90,
        position: 'center-center',
        srcset: `["60w", "90w" "120w", "180w"]`
    },
    {
        name: 'builder.contentImage',
        mode: 'resize',
        width: 317,
        height: null,
        quality: 90,
        position: 'center-center',
        srcset: `["291w", "360w", "375w", "424w", "445w", "582w", "720w", "750w", "848w", "890w"]`
    },
    {
        name: 'services.gallery',
        mode: 'resize',
        width: 648,
        height: null,
        quality: 90,
        position: 'center-center',
        srcset: `["286w", "300w", "355w", "455w", "572w", "582w", "600w", "667w", "710w", "910w", "1164w", "1334w"]`
    },
    {
        name: 'activity.card',
        mode: 'crop',
        width: 280,
        height: 202,
        quality: 90,
        position: 'center-center',
        srcset: `["288w", "300w", "576w", "600w"]`
    },

    {
        name: 'team.card',
        mode: 'crop',
        width: 282,
        height: 468,
        quality: 90,
        position: 'center-center',
        srcset: `["234w", "270w", "286w", "320w", "335w", "460w", "468w", "540w", "572w", "640w", "670w", "920w", ]`
    },

    {
        name: 'about',
        mode: 'crop',
        width: 280,
        height: 280,
        quality: 90,
        position: 'center-center',
        srcset: `["288w", "300w", "576w", "600w"]`
    }
]

const getAsset = (field = 'image', format = false) => {

    let transform

    const data = `
        url
        title
        width
        height
    `

    if ( format && typeof format == 'string' ) {
        transform = transforms.find(t => t.name == format)
    }

    if ( transform ) {

        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `
        } else {

            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                }
            `
        }

    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default getAsset
