<template>
    <card modifier="testimonial">
        <template #content>
            <clip-content
                :icon="element.image"
                :iconBgColor="iconBgColor || 'green-light'"
                :collapse="true"
            >
                <template #content>
                    <div>
                      <h5 class="t-t5">{{ element.name }}</h5>
                      <p class="t-small">{{ element.role }}</p>
                    </div>

                    <p>{{ element.quote }}</p>
                </template>
            </clip-content>
        </template>
    </card>
</template>

<script>
import Asset from 'objects/Asset';
import ClipContent from 'components/ClipContent.vue';
import Card from 'components/Card.vue';

export default {
    name: 'CardTestimonial',
    components: {
        Asset,
        ClipContent,
        Card,
    },
    props: {
        iconBgColor: String,
        element: Object,
    }
};

</script>

<style lang="scss">

.c-card.-testimonial {
    --max-width: 100%;
    --text-color: #{$color-dark};

    width: 100%;
    background-color: #{$color-green-pastel};
    border-radius: $border-radius-sm;

    .c-card__content {
        --inner-padding-x: 0;
        --inner-padding-y: 0;
    }

    .c-clip-content {
        --radius: calc( var(--grid-gutter) * 1.075);
        --margin: var(--grid-gutter-half);
        --ratio: 0.7;

        &__inner {
            padding: var(--grid-gutter-half);
            padding-top: calc(var(--grid-gutter-half) + var(--radius) / 2);
        }
        &__content {
            padding-top: var(--grid-gutter-half);
        }
    }

    .o-deco {
        left: calc( var(--grid-gutter-half) * -1 );
        right: calc( var(--grid-gutter-half) * -1 );
        opacity: 0.5;
    }
}


</style>
