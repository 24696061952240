<template>
    <article :class="className">
        <div v-if="$slots.asset" class="c-card__asset">
            <slot name="asset" />
        </div>
        <div v-if="$slots.content" class="c-card__content">
            <div class="t-content">
                <slot name="content" />
            </div>
            <slot name="afterContent" />
        </div>
    </article>
</template>

<script>

export default {
    name: 'Card',
    props: {
        modifier: {
            type: String,
            validator: (modif) =>{
                return [
                    'business-area',
                    'activity',
                    'testimonial',
                    'team',
                    'article',
                    'solution'
                ].indexOf(modif) !== -1
            }
        },
    },
    computed: {
        className() {
            let classname = 'c-card'

            if(this.modifier) {
                classname += ` -${this.modifier}`
            }

            return classname
        },
    },
};

</script>

<style lang="scss">

.c-card {
    --max-width: 100%;
    --img-max-width: 100%;
    --asset-order: 1;
    --text-color: #{$color-dark};

    color: var(--text-color);
    max-width: var(--max-width);
    height: 100%;

    .c-card__asset {
        order: var(--asset-order);
        max-width: var(--img-max-width);

        .o-asset__img {
            max-width: var(--img-max-width);
        }
    }

    .c-card__content {
        --inner-padding-x: var(--grid-gutter);
        --inner-padding-y: var(--grid-gutter);
        padding: var(--inner-padding-y) var(--inner-padding-x);
    }

}

</style>
