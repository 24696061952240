/*
 * Craft GraphQL Queries
 */

import snippets from './snippets'
import getAsset from './assets'

/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    navs: globalSet(handle: "navs") {
        ... on navs_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${snippets.defaultData}
                        title
                        section: sectionHandle
                    }
                    label
                }
            }
        }
    }
    forms: globalSet(handle: "formConfirmations") {
        ... on formConfirmations_GlobalSet {
            contact: body2
        }
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            address
            ${snippets.map}
            phone
            email
            facebook
            instagram
            linkedIn
        }
    }
}`




/**********************************
 *
 *
 *  CONTENT/PAGE
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.entry}

        ...on home_home_Entry {
            ${snippets.hero}
            ${snippets.buttons}
            ${snippets.seo}
        }
    }
    solutions: entries(section: "solutions") {
        ${snippets.solutionsPreload}
    }
    businessAreas: entries(section: "businessAreas") {
        ${snippets.businessAreasPreload}
    }
}`

// About
export const about = `{
    entry(section: "about") {
        ${snippets.entry}

        ...on about_about_Entry {
            ${snippets.hero}
            displayMap: trueOrFalse

            # Description
            descImages: ${getAsset('images', 'about')}
            descBody: body

            # Activities
            actHeading: heading2
            ${snippets.activities}

            # Team
            teamHeading: heading3
            teamBody: body2
            ${snippets.team}

            ${snippets.seo}
        }
    }
}`

// Expertise
export const expertise = `{
    entry(section: "expertiseSingle") {
        ${snippets.entry}

        ...on expertiseSingle_expertiseSingle_Entry {
            description
            ${snippets.seo}
        }
    }
    children: entries(section: "services") {
        ${snippets.services}
    }
}`

// Solutions
export const solutions = `{
    entry(section: "solutionsSingle") {
        ${snippets.entry}

        ...on solutionsSingle_solutionsSingle_Entry {
            ${snippets.hero}
            ${snippets.seo}
        }
    }
    children: entries(section: "solutions") {
        ${snippets.solutions}
    }
}`

// Business Areas
export const businessAreas = `{
    entry(section: "businessAreasSingle") {
        ${snippets.entry}

        ...on businessAreasSingle_businessAreasSingle_Entry {
            ${snippets.hero}
            ${snippets.seo}
        }
    }
    children: entries(section: "businessAreas") {
        ${snippets.businessAreas}
    }
}`





/**********************************
 *
 *
 *  BLOG AND ARTICLES
 *
 *
 **********************************/

// Blog
export const blog = `{
    entry(section: "blog") {
        ${snippets.entry}

        ...on blog_blog_Entry {
            heading
            description
            ${snippets.seo}
        }
    }
}`

// `articles` preload
export const articlesPreloadAll = `{
    entries(section: "articles", orderBy: "dateCreated DESC") {
        ${snippets.articlesPreload}
    }
}`

// Single `articles`
export const articles = slug => `{
    entry(section: "articles", slug: "${slug}") {
        ${snippets.articles}
    }
}`

export const siblings = (id, date) => `{
    prev: entry(section: "articles", after: "${date}", id: ["not", ${id}], orderBy: "dateCreated ASC") {
        ${snippets.defaultData}
    }
    next: entry(section: "articles", before: "${date}", id: ["not", ${id}], orderBy: "dateCreated DESC") {
        ${snippets.defaultData}
    }
}`


export default {
    general,
    home,
    about,
    expertise,
    solutions,
    businessAreas,
    blog,
    articlesPreloadAll,
    articles,
    siblings
}
