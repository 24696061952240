<template>
    <span
        :class="className"
        ref="loader"
    >
        <div
            class="o-loader__inner"
        >
            <span ref="bg" class="o-loader__bg"></span>

            <div>
                <span
                    id="lottie-logo"
                    class="lottie-logo"
                    ref="lottieLogo">
                </span>

                <span
                    id="lottie-texture"
                    class="lottie-texture"
                    ref="lottieTexture">
                </span>
            </div>
        </div>
    </span>
</template>


<script>

import { mapState, mapGetters } from 'vuex'
import lottie from 'lottie-web'
import logoAnim from '../../assets/animations/logo/data.json'
import textureAnim from '../../assets/animations/texture/data.json'
import { gsap } from 'gsap/all'

const LOGO_ANIMATION_DURATION = 2.25
const TEXTURE_ANIMATION_DURATION = 2

export default {
    name: 'Loader',
    data: () => ({
        loading: true,
    }),
    mounted() {
        this.$store.dispatch('loader/startLoad')

        this.bg = this.$refs.bg || document.querySelector('.o-loader__bg')
        this.loader = this.$refs.loader || document.querySelector('.o-loader')

        this.$lottieLogo = this.$refs.lottieLogo || document.getElementById('lottie-logo')
        this.$lottieTexture = this.$refs.lottieTexture || document.getElementById('lottie-texture')

        // set the animations
        this.logoAnimation = this.anim(this.$lottieLogo, logoAnim)
        this.textureAnimation = this.anim(this.$lottieTexture, textureAnim)

        //this.textureAnimation.play()

        this.firstloadIn()
    },
    computed: {
        ...mapState({
            firstload: state => state.loader.firstload
        }),

        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),

        className() {
            let classname = 'o-loader'

            if(this.firstload) {
                classname += ` -firstload`
            }

            if(this.loading) {
                classname += ' is-loading'
            }

            return classname
        },
    },
    methods: {
        anim(el, animData) {
            const params = {
                container: el,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: animData
            }

            return lottie.loadAnimation(params)
        },
        disableScroll() {
            document.body.classList.add('-scroll-disabled')
        },
        enableScoll() {
            document.body.classList.remove('-scroll-disabled')
        },

        firstloadIn() {

            this.disableScroll()
            gsap.timeline()
                .to(this.loader, {
                    yPercent: 0,
                    duration: .8,
                })
                .to(this.bg, {
                    yPercent: -100,
                    duration: .8,
                    ease: 'power3.in',
                    onStart: () => {
                        this.textureAnimation.play()
                    }
                })
                .to(this.$lottieLogo, {
                    duration: LOGO_ANIMATION_DURATION,
                    ease: 'power2.out',
                    delay: -.4,
                    onStart: () => {
                        this.logoAnimation.play()
                    },
                    onComplete: () => {
                        this.$store.dispatch('loader/endLoad')
                    }
                })
        },

        firstloadOut() {

            gsap.timeline({
                onComplete: () => {
                    this.$store.dispatch('loader/firstLoaded')
                    this.loading = false
                    this.$emit('animateOut')

                    this.logoAnimation.destroy()
                    this.textureAnimation.stop()
                    this.$lottieLogo.style.opacity = 0
                }
            })
                .to(this.loader, {
                    duration: .8,
                    '--y3': '0',
                    '--y4': '0',
                    ease: 'power2.in',
                    onStart: () => {
                        this.enableScoll()
                        this.setScroll()
                    }
                })
        },
        animateIn() {

            this.loading = true


            this.$store.dispatch('loader/startLoad')

            gsap.timeline({
                onStart: () => {
                    this.textureAnimation.play()

                },
                onComplete: () => {
                    this.$store.dispatch('loader/endLoad')
                }
            })
            .set(this.loader, {
                '--y1': '100%',
                '--y2': '100%',
                '--y3': '100%',
                '--y4': '100%',
            })
            .to(this.loader, {
                duration: .8,
                '--y1': '0',
                '--y2': '0',
                ease: 'power2.in',
                onComplete: () => {
                    window.scrollTo(0, 0)
                    this.disableScroll()
                }
            })
            .to(this.loader, {
                duration: TEXTURE_ANIMATION_DURATION,
                ease: 'power2.out',
            })
        },
        animateOut() {


            gsap.timeline({
                onComplete: () => {
                    this.$emit('animateOut')
                    this.loading = false
                    this.textureAnimation.stop()
                }
            })
            .to(this.loader, {
                '--y3': '0',
                '--y4': '0',
                duration: 0.8,
                ease: 'power3.in',
                onStart: () => {
                    this.enableScoll()
                    this.setScroll()
                },
            })
        },
        setScroll() {

            // Scroll to hash if exists
            const hash = this.$route.hash

            if (hash) {
                location.href = hash
                let el = document.querySelector(hash)

                el.scrollIntoView({
                    block: 'start',
                    inline: 'nearest',
                    behavior: 'smooth'
                })

            } else {
                window.scrollTo(0, 0)
            }

        }
    },
    watch: {
        isLoading(loading) {
            if(loading) {
                this.animateIn()
            } else if(this.firstload){
                this.firstloadOut()
            } else {
                this.animateOut()
            }
        }
    }
}

</script>

<style lang="scss">
body.-scroll-disabled {
    height: 100vh;
    overflow: hidden;
}

.o-loader {
    --y1: 0;
    --y2: 0;
    --y3: 100%;
    --y4: 100%;
    --clipPath: polygon(0 var(--y1), 100% var(--y2), 100% var(--y3), 0 var(--y4));

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50000;
    pointer-events: none;

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: z-index 0s 1s, left 0s 1s;
    background-color: $color-dark;
    clip-path: var(--clipPath);

    &.-firstload {
        // background-color: $color-green-light;

        .o-loader__bg {
            background-color: $color-green-light;
        }
    }

    &.is-loading {
        left: 0;
        transition: none;
    }

    &__inner {
        width: 100%;
        height: 100%;

        > * {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

}

.o-loader__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-dark;
    transform-origin: 50% 100%;
    will-change: transform;
    clip-path: var(--clipPath);
}

.lottie-logo {
    width: 12em;
    height: auto;
    overflow: hidden;
}

.lottie-texture {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
}
</style>
