<template>
    <section
        class="l-section"
        :style="color ? `--bg-color: var(--color-${color})` : ``"
    >
        <div class="l-section__inner | grid">
            <slot />
        </div>
    </section>
</template>

<script>

export default {
    name: 'AppSection',
    props: {
        color: String
    }
};

</script>

<style lang="scss">
.l-section {
    --bg-color: #{$color-light};
    //--section-padding: #{vh(7)};
    --section-padding: 4rem;
    --hero-after-bg: transparent;
    --text-color: #{$color-dark};

    color: var(--text-color);
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
    background-color: var(--bg-color);

    &:last-of-type {
        padding-bottom: 0;
    }
}
</style>
