<template>
    <boxed-content
        v-if="data.blocks.length > 0"
        class="b-boxed-content"
        :bgColor="data.color"
    >
        <div
            v-for="(block, i) in data.blocks"
            :key="`block-${i}`"
            class="-item"
        >
            <component
                :is="block.typeHandle"
                :data="block"
            />
        </div>

        <deco />
    </boxed-content>
</template>

<script>
import BoxedContent from 'layout/BoxedContent'

import Deco from 'objects/Deco'

// Children
import BlockAccordions from 'builder/BlockAccordions'
import BlockContent from 'builder/BlockContent'
import BlockContentImage from 'builder/BlockContentImage'
import BlockGallery from 'builder/BlockGallery'
import BlockHeading from 'builder/BlockHeading'
import BlockImage from 'builder/BlockImage'
import BlockSpacer from 'builder/BlockSpacer'

export default {
    name: 'BlockBoxedContent',
    props: {
        data: false
    },
    components: {
        BoxedContent,
        Deco,

        // Children
        BlockAccordions,
        BlockContent,
        BlockContentImage,
        BlockGallery,
        BlockHeading,
        BlockImage,
        BlockSpacer
    }
};

</script>

<style lang="scss">

.b-boxed-content {

    .-item {

        &:nth-child(n+2) {
            margin-top: var(--grid-gutter-half);
        }
        &:nth-last-child(n+2):not(~ .o-deco) {
            margin-bottom: var(--grid-gutter-half);
        }
    }

    .o-deco {
        top: calc( var(--boxed-content-padding-y) * -1 );
        left: calc( var(--boxed-content-padding-x) * -1 );
        right: calc( var(--boxed-content-padding-x) * -1 );
    }
}
</style>
