<template>
    <card modifier="activity">
        <template #content>
            <clip-content
                v-if="element.icon"
                :icon="parseIcon(element.icon)"
                :iconBgColor="element.iconBgColor || 'green-light'"
                :collapse="true"
            >
                <template #content>
                    <asset
                        v-if="element.image"
                        :asset="element.image"
                        class="c-card__asset"
                    />
                    <h4 class="c-card__title | t-t4">{{ element.label }}</h4>
                </template>
            </clip-content>
        </template>
    </card>
</template>

<script>
import Asset from 'objects/Asset'
import ClipContent from 'components/ClipContent.vue'
import Card from 'components/Card.vue'
import { parseIcon } from 'src/store/parse'

export default {
    name: 'CardActivity',
    components: {
        Asset,
        ClipContent,
        Card,
    },
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    data: () => ({ parseIcon })
};

</script>

<style lang="scss">
.c-card.-activity {
    --max-width: 300px;
    --text-color: #{$color-green-light};
    --bg-color: var(--color-dark);

    .c-card__content {
        --inner-padding-x: 0;
        --inner-padding-y: 0;

        .c-card__asset {
            border-radius: $border-radius-sm;
            overflow: hidden;
        }
        .c-card__title {
            padding: 1em 0;
            text-align: center;
        }
    }

    .c-clip-content {
        --radius: var(--grid-gutter-half);
        --ratio: 0.7;
        --margin: var(--grid-gutter-half);

        .c-clip-content__cut {
            .o-icon {
                color: $color-dark;
            }
        }
    }

    .o-deco {
        display: none;
    }
}
</style>
