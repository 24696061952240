<template>
    <tag
        :is="tag"
        class="o-contact-info | t-regular"
    >
        {{ email }}<br>
        {{ phone }}
    </tag>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ContactInfo',
    computed: {
        year: () => new Date().getFullYear(),
        ...mapState({
            email: state => state.global.settings.email,
            phone: state => state.global.settings.phone
        })
    },
    props: {
        tag: {
            type: String,
            default: 'p'
        },
    }
}
</script>
