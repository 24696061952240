<template>
    <div>
        <app-section>
            <grid-content :sticky="true" class="p-expertise__content">
                <template #left>
                    <app-hero
                        :hero="hero"
                        ref="hero"
                        class="p-expertise__hero"
                    >
                        <template #afterContent>
                            <nav class="c-btn__group -vertical">
                                <btn
                                    v-for="(service, i) in page.children"
                                    :key="`services-nav-${i}`"
                                    :label="service.shortTitle || service.title"
                                    modifier="underline"
                                    @click.native="scrollTo(service.slug)"
                                />
                            </nav>
                        </template>
                    </app-hero>
                </template>

                <template #right>
                    <content-service
                        v-for="(service, i) in page.children"
                        :key="`service-${i}`"
                        :id="service.slug"
                        :element="service"
                    />
                </template>
            </grid-content>
        </app-section>
    </div>
</template>

<script>
import AppSection from "layout/AppSection";
import AppHero from "layout/AppHero";
import GridContent from "layout/GridContent";

import Btn from "components/Btn";
import ContentService from "components/ContentService";

import { SECTION_HANDLES } from "src/constants";

import { gsap, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export default {
    name: "Expertise",
    components: {
        AppHero,
        AppSection,
        Btn,
        ContentService,
        GridContent,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null,
        };
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
    }),
    computed: {
        hero() {
            return {
                title: this.page.heading || this.page.title,
                description: this.page.description,
            };
        },
    },
    methods: {
        scrollTo(target) {
            gsap.to(window, {
                duration: 0.2,
                scrollTo: {
                    y: `#${target}`,
                    // Header height
                    offsetY: 60,
                },
                ease: "Power1.inOut",
                onStart: () => (this.isActive = false),
                onComplete: () => {
                    this.isActive = true;
                },
            });
        },
    },
};
</script>

<style lang="scss">
.p-expertise {
    .l-main {
        .l-section {
            &:first-of-type {
                padding-top: 0;
            }
        }
    }

    &__hero {
        .l-hero__text {
            padding-left: 0;
        }

        .l-hero__text--after {
            margin-top: var(--grid-gutter);
        }
    }

    &__content {
        --sticky-top: 0;

        &.l-grid-content {
            @media #{md("sm")} {
                --grid-col-gap: 0;
            }
        }

        > .l-grid-content__col.-left {
            // padding-right: var(--grid-gutter);
        }

        > .l-grid-content__col.-right {
            @media #{md("sm")} {
                padding-top: var(--grid-gutter);
            }
            @media #{md("md")} {
                padding-top: var(--grid-gutter-half);
            }
        }
    }

    #machine-learning-ia {
        // Fix: spacing on mobile
        .b-item .l-boxed-content {
            margin-right: 0;
            margin-left: 0;
        }
    }
}
</style>
