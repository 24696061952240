<template>
    <div>
        <app-hero
            :hero="hero"
            ref="hero"
            :gap="false"
            bgColor="green-emerald"
        >
            <template #media>
                <asset
                    v-if="!page.displayMap && page.image"
                    :asset="page.image"
                />
                <gmap-map
                    v-else-if="
                        page.displayMap && mapCoordinates && mapCoordinates.lng
                    "
                    class="o-map"
                    :zoom="16"
                    :center="getCoordinates(mapCoordinates)"
                    :options="googleMapsOpts"
                >
                    <gmap-custom-marker
                        :marker="getCoordinates(mapCoordinates)"
                        alignment="center"
                        :offsetY="24"
                    >
                        <div class="o-map__marker">
                            <icon icon="solia-symbol" />
                            <span>
                                <span class="t-bold t-large"> Adresse </span>
                                <address
                                    v-html="
                                        $options.filters.toBr(settings.address)
                                    "
                                    class="t-regular"
                                />
                            </span>
                        </div>
                    </gmap-custom-marker>
                </gmap-map>
            </template>
        </app-hero>

        <app-section class="p-about__first-section">
            <div
                class="p-about__images" ref="imagesContainer"
                :style="`--about-images-width: ${imagesContainerWidth}`"
            >
                <asset
                    v-for="(image, i) in page.descImages"
                    :key="`p-about-asset-${i}`"
                    :asset="image"
                    :rounded="true"
                    :cover="true"
                />
            </div>
            <div class="p-about__text">
                <div class="t-cms" v-html="page.descBody"></div>
            </div>
        </app-section>

        <app-section class="p-about__activities" color="dark">
            <div class="p-about__no-slider" v-if="!isSlider">
                <h3 class="t-t3">{{ page.actHeading }}</h3>
            </div>
            <card-grid
                :cards="page.activities"
                :slider="true"
                card-type="activity"
                :max-width="false"
                ref="activitySlider"
            >
                <template #beforeNav>
                    <h3 class="t-t3">{{ page.actHeading }}</h3>
                </template>
            </card-grid>
        </app-section>

        <app-section class="p-about__team">
            <grid-content :sticky="true">
                <template #left>
                    <h2 class="section-title | t-t3 t-cms">
                        {{ page.teamHeading }}
                    </h2>
                    <div
                        class="p-about__team--description | t-large"
                        v-html="page.teamBody"
                    ></div>
                </template>
                <template #right>
                    <card-grid :cards="page.team" card-type="team" />
                </template>
            </grid-content>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection'
import AppHero from 'layout/AppHero'
import GridContent from 'layout/GridContent'

import Asset from 'objects/Asset'
import Icon from 'objects/Icon'

import CardGrid from 'components/CardGrid'

import GmapCustomMarker from 'vue2-gmap-custom-marker'
import { mapStyles } from 'src/vendors/google-map'

import { mapState } from 'vuex'

export default {
    name: 'About',
    components: {
        AppHero,
        AppSection,
        Asset,
        CardGrid,
        GmapCustomMarker,
        GridContent,
        Icon
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        googleMapsOpts: {
            disableDefaultUi: true,
            zoomControl: false,
            zoomControlOptions: {
                style: 0,
                position: 8
            },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            draggable: false,
            styles: mapStyles
        },
        isSlider: false,
        imagesContainerWidth: 0
    }),
    mounted() {

        this.getSliderState()
        this.setImagesContainer()

        window.addEventListener('resizeEnd', () => {
            this.getSliderState()
            this.setImagesContainer()
        })
    },
    computed: {
        ...mapState({
            settings: state => state.global.settings
        }),
        hero() {
            return {
                title: this.page.heading || this.page.title,
                description: this.page.description
            }
        },
        mapCoordinates() {

            if (this.settings && this.settings.map && this.settings.map.length) {
                return this.settings.map[0]
            }

            return null
        },
    },
    methods: {
        getSliderState() {
            setTimeout(() => {
                this.isSlider = this.$refs.activitySlider.sliderIsActive
            }, 100)
        },
        getCoordinates(position) {
            return {
                lat: parseFloat(position.lat),
                lng: parseFloat(position.lng)
            }
        },
        setImagesContainer() {
            this.imagesContainerWidth = this.$refs.imagesContainer.offsetWidth + 'px'
        }
    },
    watch: {
        page() {
            this.$refs.activitySlider.setSlider()
        }
    }
};

</script>

<style lang="scss">
.p-about {
    .l-hero,
    .l-header {
        --header-bg: #{$color-green-emerald};
        --header-text-color: #{$color-dark};

        &__media {
            overflow: hidden;
            margin-right: 0;
            border-top-left-radius: $border-radius-sm;
            border-bottom-left-radius: $border-radius-sm;
            height: 100%;

            @media #{md("md")} {
                grid-row: unset;
                border-radius: 0;
                margin-left: 0;

                .o-asset {
                    display: block;

                    &__img {
                        height: 100%;
                        width: auto;
                        display: block;
                    }
                }
            }
        }
    }

    &__first-section .l-section__inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--grid-gutter);

        @media #{(md("sm"))} {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__images {
        --columns: 20;

        display: grid;
        grid-template-columns: repeat(var(--columns), 1fr);
        //grid-template-rows: repeat(12, 1fr);
        grid-auto-rows: calc(( var(--about-images-width, 550px) - 0rem * (var(--columns) - 1)) / var(--columns));

        .o-asset {
            &:first-of-type {
                grid-row: 1/12;
                grid-column: 1/12;
            }

            &:nth-of-type(2) {
                //grid-row: 5/13;
                //grid-column: 5/13;
                grid-column: 6/21;
                grid-row: 6/17;
            }
        }
    }

    &__text {
        .t-cms {
            max-width: 400px;
            margin-bottom: var(--grid-gutter);
        }
        place-self: center;
    }

    &__activities {
        --text-color: #{$color-green-light};
        margin-top: var(--grid-gutter);
        overflow: hidden;

        @media #{md('xs', "max")} {
            padding-bottom: 0;
        }

        .c-slider__nav {
            color: $color-dark;
            padding-right: var(--grid-gutter);

            @media #{md("xs")} {
                padding-right: 0;
            }
        }

        .c-slider__side {
            justify-content: space-between;
            width: 100%;
            h3 {
                line-height: 1;
            }
        }

        .c-slider__slide {
            //fix clip-content
            margin-top: 2em;
        }

        .p-about__no-slider {
            margin-bottom: calc(
                var(--grid-gutter) + 2em
            ); //2em for the clip-content radius
        }
    }

    &__team {
        --section-padding: calc(2 * var(--grid-gutter));

        .c-card-grid__grid {
            grid-row-gap: var(--grid-gutter-half) !important;
            grid-column-gap: var(--grid-gutter-half) !important;
            grid-auto-flow: row dense;
            justify-items: center;

            grid-template-columns: repeat(2, 1fr) !important;

            @media #{md("sm")} {
                grid-template-columns: repeat(1, 1fr) !important;
            }

            @media #{md("md")} {
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }

        &--description {
            padding-top: var(--grid-gutter);
            max-width: 400px;
        }
    }
}

.o-map {
    width: 100%;
    height: 30em;
    //padding-top: 100%;
    overflow: hidden;

    @media #{md("sm", "max")} {
        height: 25em;
    }

    .vue-map {
        height: 100%;
    }
    .o-map__marker {
        display: grid;
        grid-auto-flow: column;
        grid-gap: var(--grid-gutter-half);
        padding: var(--grid-gutter-half);
        padding-right: var(--grid-gutter);
        background: $color-light;
        border-radius: $border-radius-sm;

        address {
            font-style: normal;
            margin-top: 0.5em;
        }
    }
}
</style>
