<template>
    <div
        v-if="data"
        class="b-content-image"
    >
        <div class="b-content-image__content | t-cms" v-html="data.content"></div>
        <div class="b-content-image__image">
            <asset
                :asset="data.image"
                :rounded="true"
            />
        </div>
    </div>
</template>

<script>
import Asset from 'objects/Asset'

export default {
    name: "BlockContentImage",
    components: {
        Asset
    },
    props: {
        data: false
    }
}
</script>

<style lang="scss">
.b-content-image {
    display: grid;
    gap: var(--grid-gutter);

    @media #{md("md")} {
        grid-template-columns: repeat(2, 1fr);
    }

    .b-boxed-content.-green-emerald &__image {
        margin-right: calc( var(--grid-gutter) * -1 );

        .o-asset {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
</style>
